import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Container, Grid, Paper, styled, Typography, Button, Box, Chip, CircularProgress,
  List, ListItem, ListItemIcon, ListItemText, Divider, Modal, useTheme
} from '@mui/material';
import { 
  Phone, WhatsApp, Language, LinkedIn, Email, CalendarToday, 
  CheckCircleOutline, LocationOn, Star, Facebook, Twitter, Instagram, YouTube
} from '@mui/icons-material';

import YouTubeIcon from '@mui/icons-material/YouTube';
import CollectionsIcon from '@mui/icons-material/Collections';



// Assume these components are properly implemented in your project
import Header from './components/5best/profile/Header';
import BasicInfo from './components/5best/profile/BasicInfo';
import Description from './components/5best/profile/Description';
import Location from './components/5best/profile/Location';
import RatingsAndReviews from './components/5best/profile/RatingsAndReviews';
import ContactPopupModal from './components/5best/ContactPopupModal';
import EnquiryForm from './components/5best/EnquiryForm';
import ReviewsPage from './components/5best/ReviewsPage';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { EnhancedCard, ContentWrapper, colors, typography } from './theme-styles';
import { useLogin } from './contexts/LoginContext';
import FooterComponent from './FooterComponent';

const userLoggedIn= localStorage.getItem('loginState')== 'logged_in';
  const userId = localStorage.getItem('user_id');



// Styled modal components
const ResponsiveModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: 'none',  // Remove border
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2),
  width: '90%',   // Make the modal responsive
  maxWidth: '600px',  // Set max width for large screens
  borderRadius: '8px',  // Rounded corners for a clean look
  [theme.breakpoints.down('sm')]: {
    width: '95%',  // For small screens, almost full width
  },
}));


const Card3D = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(1, 0),
  transition: 'transform 0.3s, box-shadow 0.3s',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-5px) rotateX(5deg)',
    boxShadow: '0 20px 30px rgba(0, 0, 0, 0.2)',
  },
  '&:active': {
    transform: 'translateY(-8px) rotateX(8deg) scale(1.02)',
    boxShadow: '0 25px 35px rgba(0, 0, 0, 0.3)',
  },
}));

const ContactButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const BadgeImage = styled('img')({
  width: '100px', // Adjust the width for a smaller badge
  height: 'auto', // Keeps the aspect ratio intact
  margin: '0 3px', // Adjust the margin to reduce spacing between badges
  verticalAlign: 'middle', // Ensures the badge aligns with text vertically
});


const ProfilePage = () => {
  const [profileData, setProfileData] = useState(null);
  const [contactPopup, setContactPopup] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openEnquiry, setOpenEnquiry] = useState(false);
  const { id } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);  // 'youtube' or 'gallery'
  const [bannerData, setBannerData] = useState([]);
  const [bannerPositions, setBannerPositions] = useState([]);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [openEnquiryModal, setOpenEnquiryModal] = useState(false);
  const [contactPopupData, setContactPopupData] = useState('');
  const theme = useTheme();
  const isUserLoggedIn = userLoggedIn; // Replace with actual login check
  const [openContactPopup, setOpenContactPopup] = useState(false);
  const [selectedListingId, setSelectedListingId] = useState(null);


  // Fetch profile data on component mount or when the `id` changes
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`https://developer.2il.org/Appdev/jsonapi/5best/profile-details.php?proid=${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch profile data');
        }
        const data = await response.json();
        if (data && data.listdata) {
          setProfileData(data);
        } else {
          throw new Error('Invalid data structure received from API');
        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, [id]);

  // Fetch listing data when profile data is available
  useEffect(() => {
    const fetchListingData = async () => {
      try {
        if (!profileData) return;

        const { subcat_urlslug, city_urlslug } = profileData.listdata;

        // Construct the dynamic URL
        const dynamicUrl = `https://developer.2il.org/Appdev/jsonapi/5best/listingapi.php?listid=${subcat_urlslug}-in-${city_urlslug}`;

        const response = await fetch(dynamicUrl);
        const data = await response.json();
        setContactPopupData(data.contact_popup);

        data.banner = "6481620854999,7835723583677";

        if (data.banner) {
          const bannerResponse = await fetch('https://5bestincity.com/api/bannerfetchinfo.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `id=${data.banner}`,
          });
          const bannerData = await bannerResponse.json();
          setBannerData(bannerData.data);
          setBannerPositions(bannerData.position.split(',').map(Number));
        }
      } catch (error) {
        setError('Failed to fetch listing data');
      }
    };

    fetchListingData();
  }, [profileData]); // Only runs when `profileData` is available
  
  

  const handleOpenEnquiry = () => setOpenEnquiry(true);
  const handleCloseEnquiry = () => setOpenEnquiry(false);
  

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography color="error">Error: {error}</Typography>
      </Box>
    );
  }

  if (!profileData || !profileData.listdata) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography>No data available</Typography>
      </Box>
    );
  }
  
 

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalContent(null);
  };


 const handleOpenContactModal = (action, { contactPopupData }) => {
    // Optionally use `action` and details to determine modal behavior
    setSelectedListingId(contactPopupData);  // Assuming `phone` is your listingId for this example
    setOpenContactPopup(true);
  };

  const handleCloseContactPopup = () => {
    setOpenContactPopup(false);
  };
  
  const handleButtonClick = (action, value) => {
	  
 const showContactModal = contactPopupData;
 
		//alert(showContactModal);
		if(showContactModal){
      // If contactPopupData is present, trigger the modal
     setSelectedListingId(contactPopupData);  // Assuming `phone` is your listingId for this example
    setOpenContactPopup(true);
		}else{
			
			handleOpenEnquiry();
		}
};

  const { listdata, badgeinfo = [], contactinfo = {} } = profileData;

  const images = [
    listdata.sub_list_image_1,
    listdata.sub_list_image_2,
    listdata.sub_list_image_3
  ].filter(img => img != null);

  const serviceDetails = listdata.service_details ? listdata.service_details.split('\r\n') : [];
  const timingDetails = listdata.timing ? listdata.timing.split('\r\n') : [];
  const pricingDetails = listdata.service_pricing ? listdata.service_pricing.split('\r\n') : [];
  const onlypricingDetails = listdata.service_pricing!=='$$]]';
  const achievementDetails = listdata.achievement_awards ? listdata.achievement_awards.split('\r\n') : [];

  const showEnquiryForm = badgeinfo.length === 0;
  
    const bannerSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <Container maxWidth="lg">
	  {/* 3D Banner Slider */}
      {bannerData.length > 0 && (
<Box
  sx={{
    marginBottom: 4,
    marginTop: 4,
    perspective: '1000px',
    '@media (max-width: 600px)': {
      img: {
        minHeight: '50px',  // Increased height for mobile screens
        width: '100%',
        objectFit: 'cover',
        borderRadius: '4px',
      },
    },
    '@media (min-width: 601px)': {
      img: {
        maxHeight: '450px',  // Height for larger screens
        width: '100%',
        objectFit: 'cover',
        borderRadius: '4px',
      },
    },
  }}
>
  <Slider {...bannerSliderSettings}>
    {bannerData.map((banner, index) => (
      <div key={index}>
        <img
          src={`https://5bestincity.com/images/public/banner/${banner.imagesize1}`}
          alt={`Banner ${index + 1}`}
          style={{
            width: '100%',
            objectFit: 'cover',  // Ensures the image scales while maintaining aspect ratio
            borderRadius: '4px',
          }}
        />
      </div>
    ))}
  </Slider>
</Box>




      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          
<Box sx={{ position: 'relative', display: 'inline-block', width: '100%' }}>
  <Header images={images} businessName={listdata.business_name || ''} />

  {/* Gallery Icon positioned at the bottom-left with circular background */}
  <Box sx={{ position: 'absolute', bottom: '25px', left: '10px' }}>
    {listdata.is_microsite && (
      <Button
        onClick={() => handleOpenModal('gallery')}
        sx={{
          minWidth: 0,
          width: 50,
          height: 50,
          borderRadius: '50%',
          bgcolor: 'rgba(0, 0, 0, 0.6)',  // Semi-transparent black background
          '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.8)' },  // Darken on hover
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CollectionsIcon sx={{ fontSize: 30, color: '#FFFFFF' }} />
      </Button>
    )}
  </Box>

  {/* YouTube Icon positioned at the bottom-right with circular background */}
  <Box sx={{ position: 'absolute', bottom: '25px', right: '10px' }}>
    {listdata.promotional_video_URL && (
      <Button
        onClick={() => handleOpenModal('youtube')}
        sx={{
          minWidth: 0,
          width: 50,
          height: 50,
          borderRadius: '50%',
          bgcolor: 'rgba(0, 0, 0, 0.6)',  // Semi-transparent black background
          '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.8)' },  // Darken on hover
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <YouTubeIcon sx={{ fontSize: 30, color: '#FFFFFF' }} />
      </Button>
    )}
  </Box>
</Box>
<Box sx={{ position: 'relative', display: 'inline-block', width: 'auto' }}>
  <ResponsiveModal
    open={modalOpen}
    onClose={handleCloseModal}
    aria-labelledby="responsive-modal-title"
    aria-describedby="responsive-modal-description"
    disableScrollLock={true}  
  >
    <ModalContent
      sx={{
        width: '90%',              // Full width for larger screens
        maxWidth: '400px',          // Set max width to control size on larger screens
        maxHeight: '85vh',          // Prevent overflow by limiting height to 85% of viewport
        overflowY: 'auto',          // Enable scrolling if content exceeds height
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        borderRadius: '8px',
        [theme.breakpoints.down('sm')]: {
          width: '100%',           // Full width for mobile devices
          maxHeight: '90vh',       // Increased height for mobile
        },
      }}
    >
      {modalContent === 'youtube' && listdata.promotional_video_URL && (
        <iframe
          width="100%"
          height="315px"             // Fixed height for iframe on mobile
          src={listdata.promotional_video_URL}
          title="YouTube video player"
          frameBorder="0"
          allowFullScreen
          style={{ borderRadius: '8px' }}  // Ensure rounded corners
        />
      )}
      {modalContent === 'gallery' && listdata.is_microsite && (
        <iframe
          src={listdata.is_microsite}
          title="Gallery"
          width="100%"
          height="500px"            // Set a fixed height for gallery iframe
          style={{ border: 'none', borderRadius: '8px' }}
        />
      )}
    </ModalContent>
  </ResponsiveModal>
</Box>

     

     

            <Box display="flex" justifyContent="center" mt={2}>
              {badgeinfo.map((badge, index) => (
                <BadgeImage key={index} src={badge.badge_url} alt={badge.badge_icon} />
              ))}
            </Box>
         
        </Grid>
        <Grid item xs={12} md={8}>
        
   <BasicInfo 
  name={listdata.business_name || ''}
  rating={listdata.sub_rating || 0}
  ratingCount={listdata.rating_count || 0}
  address={`${listdata.address_line_1 || ''}${listdata.address_line_2 ? ', ' + listdata.address_line_2 : ''}, ${listdata.city_name || ''}, ${listdata.state_name || ''}, ${listdata.country_name || ''} - ${listdata.address_pincode || ''}`}
  phone={listdata.business_phone_1 || ''}
  email={listdata.business_email || ''}
  contactinfo={contactinfo}
  badgeinfo={badgeinfo}
  onOpenEnquiry={handleOpenEnquiry}
  contactPopupData={contactPopupData}
  onOpenContactModal={handleOpenContactModal}
  directionurl={listdata.direction_URL || ''}
  listingId={profileData.listdata.listing_id}
  
/>
       {listdata.promotional_video_URL && (
            <Box sx={{  margin:colors.sectionmb  }}>
              <Typography variant="h6" sx={{ ...typography.h5, color: colors.primary,fontSize: '18px', fontWeight: 'bold' }} gutterBottom>Promotional Video</Typography>
              <Box
                component="iframe"
                src={listdata.promotional_video_URL}
                width="100%"
                height="315"
                sx={{ border: 0 }}
                allowFullScreen
              />
            </Box>
          )}
        
		  {listdata.business_since && (
          <Box sx={{ margin:colors.sectionmb }}>
            <Typography variant="h6" sx={{ ...typography.h5, color: colors.primary,fontSize: '18px', fontWeight: 'bold' }} gutterBottom>In Business Since</Typography>
            <Typography variant="body1">{listdata.business_since || 'N/A'}</Typography>
         </Box>
		  )}
          <Box sx={{ margin:colors.sectionmb }}>
            <Description description={listdata.list_about || ''} />
          </Box>
          <Box sx={{ margin:colors.sectionmb }}>
            <Typography variant="h6" sx={{ ...typography.h5, color: colors.primary,fontSize: '18px', fontWeight: 'bold' }} gutterBottom>Services</Typography>
           <List sx={{ '&.MuiList-root': { listStyleType: 'none' } }}>
			  {serviceDetails.map((service, index) => (
				<ListItem key={index} sx={{ padding: '0px' }}>
				  <ListItemIcon sx={{ minWidth:'30px' }}>
			  <CheckCircleOutline color="primary" sx={{ color: '#222' }} />
				  </ListItemIcon>
				  <ListItemText primary={service.trim()} />
				</ListItem>
			  ))}
			</List>
          </Box>
          {timingDetails.length > 0 && (
            <Box sx={{margin:colors.sectionmb}}>
              <Typography variant="h6" sx={{ ...typography.h5, color: colors.primary,fontSize: '18px', fontWeight: 'bold' }} gutterBottom>Business Hours</Typography>
              <List>
                {timingDetails.map((timing, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={timing.trim()} />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          {pricingDetails.length > 0 && onlypricingDetails && (
            <Box sx={{  margin:colors.sectionmb  }}>
              <Typography variant="h6" sx={{ ...typography.h5, color: colors.primary,fontSize: '18px', fontWeight: 'bold' }} gutterBottom>Pricing</Typography>
              <List>
                {pricingDetails.map((pricing, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={pricing.trim()} />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          {achievementDetails.length > 0 && (
            <Box sx={{  margin:colors.sectionmb  }}>
              <Typography variant="h6" sx={{ ...typography.h5, color: colors.primary,fontSize: '18px', fontWeight: 'bold' }} gutterBottom>Achievements & Awards</Typography>
              <List>
                {achievementDetails.map((achievement, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <Star color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={achievement.trim()} />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          {listdata.certification_license && (
            <Box sx={{  margin:colors.sectionmb  }}>
              <Typography variant="h6" sx={{ ...typography.h5, color: colors.primary,fontSize: '18px', fontWeight: 'bold' }} gutterBottom>Certifications & Licenses</Typography>
              <Typography variant="body1">{listdata.certification_license}</Typography>
            </Box>
          )}
          {listdata.special_discount && (
            <Box sx={{  margin:colors.sectionmb  }}>
              <Typography variant="h6" sx={{ ...typography.h5, color: colors.primary,fontSize: '18px', fontWeight: 'bold' }} gutterBottom>Special Discount</Typography>
              <Typography variant="body1">{listdata.special_discount}</Typography>
            </Box>
          )}
		   <Typography variant="h6" sx={{ ...typography.h5, color: colors.primary,fontSize: '18px', fontWeight: 'bold' }} gutterBottom>Contact & Social</Typography>
          <Box sx={{ margin:colors.sectionmb }}> 
           
            <Box display="flex" flexWrap="wrap">
							 {showEnquiryForm ? (
				  <>
	
  {contactinfo.is_call === "true" && (
    <ContactButton 
      startIcon={<Phone />} 
      variant="contained" 
      color="primary" 
	  sx={{backgroundColor:colors.callbuttonbg,borderRadius:colors.borderRadius25}}
      onClick={() => handleButtonClick('call', listdata.business_phone_1)}
    >
      Call
    </ContactButton>
  )}
  {contactinfo.is_whatsapp === "true" && (
    <ContactButton 
      startIcon={<WhatsApp />} 
      variant="contained" 
      color="secondary" 
	  sx={{backgroundColor:colors.whatsappbuttonbg,borderRadius:colors.borderRadius25}}
      onClick={() => handleButtonClick('whatsapp', listdata.business_whatsapp)}
    >
      WhatsApp
    </ContactButton>
  )}
  <ContactButton 
    startIcon={<Email />} 
    variant="contained" 
    color="info" 
	sx={{backgroundColor:colors.commonbtnbg,boxShadow:colors.bxshadowbtn,borderRadius:colors.borderRadius25, color: colors.commonbtntextcolor}}
    onClick={() => handleButtonClick('email', listdata.business_email)}
  >
    Email
  </ContactButton>
  {listdata.website_URL && (
    <ContactButton 
      startIcon={<Language />} 
      variant="contained" 
      color="success" 
	  sx={{backgroundColor:colors.commonbtnbg,boxShadow:colors.bxshadowbtn,borderRadius:colors.borderRadius25, color: colors.commonbtntextcolor}}
      onClick={() => handleButtonClick('website', listdata.website_URL)}
    >
      Website
    </ContactButton>
  )}
  {listdata.profile_URL_fb && (
    <ContactButton 
      startIcon={<Facebook />} 
      variant="contained" 
      style={{ backgroundColor: '#3b5998', color: 'white' }} 
      sx={{backgroundColor:colors.commonbtnbg,boxShadow:colors.bxshadowbtn,borderRadius:colors.borderRadius25, color: colors.commonbtntextcolor}}	  
      onClick={() => handleButtonClick('facebook', listdata.profile_URL_fb)}
    >
      Facebook
    </ContactButton>
  )}
  {listdata.profile_URL_tw && (
    <ContactButton 
      startIcon={<Twitter />} 
      variant="contained" 
      style={{ backgroundColor: '#1DA1F2', color: 'white' }}  
	  sx={{backgroundColor:colors.commonbtnbg,boxShadow:colors.bxshadowbtn,borderRadius:colors.borderRadius25, color: colors.commonbtntextcolor}}
      onClick={() => handleButtonClick('twitter', listdata.profile_URL_tw)}
    >
      Twitter
    </ContactButton>
  )}
  {listdata.profile_URL_ints && (
    <ContactButton 
      startIcon={<Instagram />} 
      variant="contained" 
      style={{ backgroundColor: '#C13584', color: 'white' }} 
	  sx={{backgroundColor:colors.commonbtnbg,boxShadow:colors.bxshadowbtn,borderRadius:colors.borderRadius25, color: colors.commonbtntextcolor}}
      onClick={() => handleButtonClick('instagram', listdata.profile_URL_ints)}
    >
      Instagram
    </ContactButton>
  )}
  {listdata.profile_URL_linkdn && (
    <ContactButton 
      startIcon={<LinkedIn />} 
      variant="contained" 
      style={{ backgroundColor: '#0077b5', color: 'white' }} 
	  sx={{backgroundColor:colors.commonbtnbg,boxShadow:colors.bxshadowbtn,borderRadius:colors.borderRadius25, color: colors.commonbtntextcolor}}
      onClick={() => handleButtonClick('linkedin', listdata.profile_URL_linkdn)}
    >
      LinkedIn
    </ContactButton>
  )}
  {listdata.profile_URL_youtube && (
    <ContactButton 
      startIcon={<YouTube />} 
      variant="contained" 
      style={{ backgroundColor: '#FF0000', color: 'white' }} 
	  sx={{backgroundColor:colors.commonbtnbg,boxShadow:colors.bxshadowbtn,borderRadius:colors.borderRadius25, color: colors.commonbtntextcolor}}
      onClick={() => handleButtonClick('youtube', listdata.profile_URL_youtube)}
    >
      YouTube
    </ContactButton>
  )}

				  </>
				) : (
				  <>
				{contactinfo.is_call === "true" && (
  <ContactButton 
    startIcon={<Phone />} 
    variant="contained" 
    color="primary" 
    sx={{
      backgroundColor: colors.callbuttonbg,
      borderRadius: colors.borderRadius25
    }} 
    href={`tel:${listdata.business_phone_1}`}
  >
    Call
  </ContactButton>
)}

{contactinfo.is_whatsapp === "true" && (
  <ContactButton 
    startIcon={<WhatsApp />} 
    variant="contained" 
    color="secondary" 
    sx={{
      backgroundColor: colors.whatsappbuttonbg,
      borderRadius: colors.borderRadius25
    }} 
    href={`https://wa.me/${listdata.business_whatsapp}`}
  >
    WhatsApp
  </ContactButton>
)}

<ContactButton 
  startIcon={<Email />} 
  variant="contained" 
  color="info" 
  sx={{
    backgroundColor: colors.commonbtnbg,
    boxShadow: colors.bxshadowbtn,
	 color: colors.commonbtntextcolor,
    borderRadius: colors.borderRadius25
  }} 
  href={`mailto:${listdata.business_email}`}
>
  Email
</ContactButton>

{listdata.website_URL && (
  <ContactButton 
    startIcon={<Language />} 
    variant="contained" 
    color="success" 
    sx={{
      backgroundColor: colors.commonbtnbg,
      boxShadow: colors.bxshadowbtn,
	   color: colors.commonbtntextcolor,
      borderRadius: colors.borderRadius25
    }} 
    href={listdata.website_URL} 
    target="_blank" 
    rel="noopener noreferrer"
  >
    Website
  </ContactButton>
)}

{listdata.profile_URL_fb && (
  <ContactButton 
    startIcon={<Facebook />} 
    variant="contained" 
    sx={{
       backgroundColor: colors.commonbtnbg,
      color: colors.commonbtntextcolor,
      boxShadow: colors.bxshadowbtn,
      borderRadius: colors.borderRadius25
    }} 
    href={listdata.profile_URL_fb} 
    target="_blank" 
    rel="noopener noreferrer"
  >
    Facebook
  </ContactButton>
)}

{listdata.profile_URL_tw && (
  <ContactButton 
    startIcon={<Twitter />} 
    variant="contained" 
    sx={{
      backgroundColor: colors.commonbtnbg,
       color: colors.commonbtntextcolor,
      boxShadow: colors.bxshadowbtn,
      borderRadius: colors.borderRadius25
    }} 
    href={listdata.profile_URL_tw} 
    target="_blank" 
    rel="noopener noreferrer"
  >
    Twitter
  </ContactButton>
)}

{listdata.profile_URL_ints && (
  <ContactButton 
    startIcon={<Instagram />} 
    variant="contained" 
    sx={{
      backgroundColor: colors.commonbtnbg,
       color: colors.commonbtntextcolor,
      boxShadow: colors.bxshadowbtn,
      borderRadius: colors.borderRadius25
    }} 
    href={listdata.profile_URL_ints} 
    target="_blank" 
    rel="noopener noreferrer"
  >
    Instagram
  </ContactButton>
)}

{listdata.profile_URL_linkdn && (
  <ContactButton 
    startIcon={<LinkedIn />} 
    variant="contained" 
    sx={{
      backgroundColor: colors.commonbtnbg,
       color: colors.commonbtntextcolor,
      boxShadow: colors.bxshadowbtn,
      borderRadius: colors.borderRadius25
    }} 
    href={listdata.profile_URL_linkdn} 
    target="_blank" 
    rel="noopener noreferrer"
  >
    LinkedIn
  </ContactButton>
)}

{listdata.profile_URL_youtube && (
  <ContactButton 
    startIcon={<YouTube />} 
    variant="contained" 
    sx={{
      backgroundColor: colors.commonbtnbg,
      boxShadow: colors.bxshadowbtn,
      borderRadius: colors.borderRadius25,
	  color: colors.commonbtntextcolor
    }} 
    href={listdata.profile_URL_youtube} 
    target="_blank" 
    rel="noopener noreferrer"
  >
    YouTube
  </ContactButton>
)}

				  </>
				)}

            </Box>
          </Box>
          {listdata.callaction_name && listdata.callaction_URL && (
            <Box sx={{ margin:colors.sectionmb  }}>
              <Typography variant="h6" sx={{ ...typography.h5, color: colors.primary,fontSize: '18px', fontWeight: 'bold' }} gutterBottom>Appointment</Typography>
			   {showEnquiryForm ? (
				  <>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CalendarToday />}
                onClick={() => handleButtonClick('appointment', listdata.callaction_URL)}
                rel="noopener noreferrer"
              >
                {listdata.callaction_name}
              </Button>
			   </>
				) : (
				 <Button
                variant="contained"
                color="primary"
                startIcon={<CalendarToday />}
                href={listdata.callaction_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {listdata.callaction_name}
              </Button>
				
				
			   )}
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{  margin:colors.sectionmb }}>
            <Location 
              mapUrl={listdata.map_location || ''}
              address={`${listdata.address_line_1 || ''}${listdata.address_line_2 ? ', ' + listdata.address_line_2 : ''}, ${listdata.city_name || ''}, ${listdata.state_name || ''}, ${listdata.country_name || ''} ${listdata.address_pincode || ''}`}
            />
          </Box>
            <Box sx={{  margin:colors.sectionmb  }}>
            <ReviewsPage listingId={id} />
          </Box>
   
        </Grid>
      </Grid>
	   <ContactPopupModal
        open={openContactPopup}
        onClose={handleCloseContactPopup}
        listingId={selectedListingId}
        contactPopupData={contactPopupData}
      />
      <EnquiryForm 
        open={openEnquiry} 
        onClose={handleCloseEnquiry} 
        businessName={listdata.business_name || ''} 
        businessImage={images[0] || ''}
		subcatname={listdata.sub_category_name || ''}  // Add this line
        cityname={listdata.city_name || ''}  // Add this line
		cityurlslug={listdata.city_urlslug}
		subcaturlslug={listdata.subcat_urlslug}
		listingId={listdata.listing_id}
      />
	  <FooterComponent />
    </Container>
  );
};

export default ProfilePage;