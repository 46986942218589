
import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Button, Box } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ListingCard from './components/5best/ListingCard'; // Assuming ListingCard is the component for each listing card
import { openDB } from 'idb';

const PinnedBusinessesPage = () => {
  const [pinnedListings, setPinnedListings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPinnedListings = async () => {
      const db = await openDB('PinnedBusinesses', 1);
      const pins = await db.getAll('pins');

      const fetchedListings = await Promise.all(
        pins.map(async (pin) => {
          try {
            // Fetch subcat_urlslug, city_urlslug, and listing_id from profile-details API
            const profileResponse = await fetch(`https://developer.2il.org/Appdev/jsonapi/5best/profile-details.php?proid=${pin.id}`);
            const profileData = await profileResponse.json();
            const { subcat_urlslug, city_urlslug, listing_id } = profileData.listdata;

            // Use subcat_urlslug and city_urlslug to get the listing details
            const listingResponse = await fetch(`https://developer.2il.org/Appdev/jsonapi/5best/listingapi.php?listid=${subcat_urlslug}-in-${city_urlslug}`);
            const listingData = await listingResponse.json();

            // Match the listing_id from profile-details with the listing_id from the listingapi.php
            const matchedListing = listingData.listalldata.find(listing => listing.listdata.listing_id === listing_id);
            return matchedListing; // Return the matched listing
          } catch (error) {
            console.error('Error fetching listing details:', error);
            return null;
          }
        })
      );

      
      setPinnedListings(fetchedListings.filter(Boolean)); // Filter out null values
      setLoading(false);
    };

    fetchPinnedListings();
  }, []);

  // Handle call and WhatsApp actions
  const handleClick = (listingData, action) => {
    if (action === 'call') {
      window.location.href = `tel:${listingData.listdata.business_phone_1}`;
    } else if (action === 'chat') {
      window.location.href = `https://wa.me/${listingData.listdata.business_whatsapp}`;
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (pinnedListings.length === 0) {
    return <Typography>No pinned businesses found.</Typography>;
  }

  return (
    <Container maxWidth="lg" sx={{ marginTop: 4 }}>
      <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: 4 }}>
        Your Pinned Businesses
      </Typography>
      <Grid container spacing={4}>
        {pinnedListings.map((listingData, index) => (
          <ListingCard key={index} listingData={listingData} handleClick={handleClick} />
        ))}
      </Grid>
    </Container>
  );
};

// ListingCard component with button design
const ListingCardpin = ({ listingData, handleClick }) => {
  const handleAction = (action) => {
    if (handleClick) {
      handleClick(listingData, action);  // Pass the listing data and action (call, chat)
    }
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box sx={{ padding: 2, boxShadow: 3, borderRadius: '8px', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)' } }}>
        <Typography variant="h5" sx={{ color: '#CF5F39', fontWeight: 'bold', marginBottom: 2 }}>
          {listingData.listdata.business_name}
        </Typography>

        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="body1" color="textSecondary">
            {listingData.listdata.address_line_1}, {listingData.listdata.city_name}, {listingData.listdata.state_name}
          </Typography>
        </Box>

        {/* Call and WhatsApp buttons */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
          <Button
            onClick={() => handleAction('call')}
            variant="contained"
            startIcon={<PhoneIcon />}
            sx={{
              backgroundColor: '#FF5722',
              '&:hover': { backgroundColor: '#E64A19' },
              color: '#fff',
              borderRadius: '20px',
              padding: '10px 20px',
              boxShadow: '0 3px 6px rgba(0,0,0,0.1)',
              fontWeight: 'bold',
              textTransform: 'none',
            }}
          >
            Call
          </Button>
          <Button
            onClick={() => handleAction('chat')}
            variant="contained"
            startIcon={<WhatsAppIcon />}
            sx={{
              backgroundColor: '#25D366',
              '&:hover': { backgroundColor: '#128C7E' },
              color: '#fff',
              borderRadius: '20px',
              padding: '10px 20px',
              boxShadow: '0 3px 6px rgba(0,0,0,0.1)',
              fontWeight: 'bold',
              textTransform: 'none',
            }}
          >
            Chat
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

export default PinnedBusinessesPage;
