import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NearMePage from './NearMePage';
import HomePage from './HomePage';
import WebAppsPage from './WebAppsPage';
import CategoryPage from './components/webapp/CategoryPage';
import CategoryHome from './CategoryHome';
import SearchResultsPage from './components/5best/SearchResultsPage';
import CityPage from './CityPage';
import ProfilePage from './ProfilePage';
import ListingPage from './ListingPage';
import LoginPage from './LoginPage';
import LogoutPage from './LogoutPage';
import LoginCheck from './LoginCheck';
import ReviewsPage from './components/5best/ReviewsPage';
import { CssBaseline } from '@mui/material';
import { SessionProvider } from './SessionManager';
import './App.css';
import { LoginProvider } from './contexts/LoginContext';
import ProductsPage from './components/amazon/ProductsPage'; // Products listing page
import ProductDetailPage from './components/amazon/ProductDetailPage'; // Individual product detail page
import PinnedBusinessesPage from './PinnedBusinessesPage'; // Your new page
import BestLocationsPage from './BestLocationsPage';
import CollectionsPage from './components/collections/CollectionsPage';

function App() {
  useEffect(() => {
    console.log("App component mounted");
    const link = document.createElement('link');
    link.href = 'https://top5incity.com/Appdev/css/appstyle.css';
    link.rel = 'stylesheet';
    link.type = 'text/css';
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <LoginProvider>
      <Router>
        <CssBaseline />
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/:subcategoryslug/:cityslug" element={<ListingPage />} />
          <Route path="/login-check/:state/:userId" element={<LoginCheck />} />
          <Route path="/categorypage/:cityId" element={<CategoryHome />} />
          <Route path="/nearme" element={<NearMePage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/webapps" element={<WebAppsPage />} />
          <Route path="/category/:categoryId" element={<CategoryPage />} />
          <Route path="/cities" element={<CityPage />} />
          <Route path="/reviews/:listingId" element={<ReviewsPage />} />
          <Route path="/profile/:id" element={<ProfilePage />} />
		  <Route path="/amazon" element={<ProductsPage />} />
         <Route path="/amazon/:productId" element={<ProductDetailPage />} />
		 <Route path="/pinned-business" element={<PinnedBusinessesPage />} />
		 <Route path="/:subcategory" element={<BestLocationsPage />} />
		 <Route path="/collections" element={<CollectionsPage />} />
        </Routes>
      </Router>
    </LoginProvider>
  );
}

export default App;