import React, { useEffect, useRef } from 'react';
import { Box, Button, Avatar,Typography } from '@mui/material';
import { colors } from '../../theme-styles';

const CategorySection = ({ categories, onCategoryClick, activeCategory, isSticky, isSearchActive }) => {
  const containerRef = useRef(null);
  const buttonsRef = useRef({});

  useEffect(() => {
    const scrollActiveButtonIntoView = () => {
      const activeButton = buttonsRef.current[activeCategory];
      if (activeButton && containerRef.current) {
        const container = containerRef.current;
        const buttonRect = activeButton.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        if (buttonRect.left < containerRect.left) {
          container.scrollTo({
            left: container.scrollLeft + buttonRect.left - containerRect.left - 20,
            behavior: 'smooth'
          });
        } else if (buttonRect.right > containerRect.right) {
          container.scrollTo({
            left: container.scrollLeft + buttonRect.right - containerRect.right + 20,
            behavior: 'smooth'
          });
        }
      }
    };

    scrollActiveButtonIntoView();
  }, [activeCategory]);

  return (
    <Box 
      ref={containerRef}
      sx={{
        marginLeft: isSticky ? '-40px': '-15px',
        display: 'flex',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        padding: '10px',
        marginTop: 0,
        marginBottom: '16px',
        position: isSticky ? 'fixed' : 'relative',
        top: isSticky ? (isSearchActive ? '60px' : 0) : 'auto',
        zIndex: isSticky ? 1090 : 'auto',
        backgroundColor: isSticky ? colors.stickyBackground : colors.secondaryBackground,
        boxShadow: isSticky ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none',
        borderBottom: `1px solid ${colors.categoryBorder}`,
        background: colors.categoryBackground,
        alignItems: 'center',
        width: isSticky ? '109%' : '109%',
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        scrollbarWidth: 'none',
        transition: 'all 0.3s ease-in-out',
      }}
    >
      {categories.map((category) => (
        <Button
          key={category.subcat_id}
          ref={(el) => (buttonsRef.current[category.sub_category_name] = el)}
          onClick={() => onCategoryClick(category.sub_category_name)}
          sx={{
			  minHeight: '55px',
					backgroundColor:
		  activeCategory === category.sub_category_name
			? colors.categoryActive  // Pink color for active buttons
			: colors.accent2Light,
            color:
              activeCategory === category.sub_category_name 
                ? colors.categoryActiveText 
                : colors.commonbtntextcolor,
            borderRadius: colors.borderRadius35,
            fontSize: '11px',
            padding: '6px 15px 6px 6px',
            marginRight: '8px',
            border:
              activeCategory === category.sub_category_name
                ? 'none'
                : `1px solid ${colors.categoryBorder}`,
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            minWidth: '100px',
            textAlign: 'center',
            flexShrink: 0,
            fontWeight: activeCategory === category.sub_category_name ? 'bold' : 'normal',
            boxShadow: activeCategory === category.sub_category_name ? colors.bxshadowbtn : 'none',
            transition: 'all 0.3s ease-in-out',
            fontSize: '1rem',
            '&:hover': {
              backgroundColor:
			  activeCategory === category.sub_category_name
				? colors.categoryActive  
				: colors.accent2Light,
              opacity: activeCategory === category.sub_category_name ? 0.9 : 1,
            },
          }}
        >
          {category.sub_category_name === 'All' ? (
          <Typography sx={{
                  
                  marginLeft: '8px',
                  backgroundColor: 'transparent',
                }}>  ALL </Typography>
          ) : (
            <>
              <Avatar
                src={category.category_image}
                alt={category.sub_category_name}
                sx={{
                  width: 40,
                  height: 40,
                  marginRight: 1,
                  backgroundColor: 'transparent',
                }}
              />
              {category.sub_category_name}
            </>
          )}
        </Button>
      ))}
    </Box>
  );
};

export default CategorySection;