import React from 'react';
import { Typography } from '@mui/material';
import { EnhancedCard, ContentWrapper, colors, typography} from '../../../theme-styles';


const Description = ({ description }) => (
 
    <ContentWrapper sx={{ padding:'0px',boxShadow:'none' }}>
      <Typography variant="h5" component="h3" gutterBottom sx={{ ...typography.h5, color: colors.primary,fontSize: '18px', fontWeight: 'bold' }}>About Us</Typography>
      <Typography variant="body1" sx={{ ...typography.body1, color: colors.primaryText }}>{description}</Typography>
    </ContentWrapper>
 
);

export default Description;