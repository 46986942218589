import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Container, Typography, Box } from '@mui/material';
import { debounce } from 'lodash';
import Banner from './components/nearme/Banner';
import SearchEngineSection from './components/nearme/SearchEngineSection';
import CategorySection from './components/nearme/CategorySection';
import LinksSection from './components/nearme/LinksSection';
import FooterComponent from './FooterComponent';
import {
  MainContainer,CategoryItem, colors,
  BannerContainer,
  SearchBoxContainer,
  CategorySectionContainer,
  CategoryWrapper,
  LinksSectionContainer
} from './theme-styles';

const NearMePage = () => {
  const [data, setData] = useState(null);
  const [activeCategory, setActiveCategory] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [isCategorySticky, setIsCategorySticky] = useState(false);
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const [selectedEngines, setSelectedEngines] = useState([]);
  const [showEngines, setShowEngines] = useState(false);
  const sectionRefs = useRef({});
  const isManuallyScrolling = useRef(false);
  const scrollTimeout = useRef(null);
  const searchBoxRef = useRef(null);
  const categorySectionRef = useRef(null);
  const bannerRef = useRef(null);
  const lastScrollPosition = useRef(0);
  const clickedCategory = useRef(null);
  const containerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredLinks, setFilteredLinks] = useState([]);
  
useEffect(() => {
  if (!searchQuery) {
    // Reset to show all links when searchQuery is empty
    setFilteredLinks(data?.linkdata || []);
  }
}, [searchQuery, data]);



  useEffect(() => {
    fetch('https://developer.2il.org/Appdev/jsonapi/nearmejson.php')
      .then((response) => response.json())
      .then((json) => {
        const uniqueCategories = [...new Map(
          json.subcatdata.map(item => [item['subcat_id'], item])
        ).values()];

        const uniqueLinkData = json.linkdata.map(category => ({
          ...category,
          links: [...new Map(
            category.links.map(link => [link['link_id'], link])
          ).values()]
        }));

        const initialSelectedEngines = json.searchengine.searchenginedata.map(engine => ({
          ...engine,
          selected: engine.selected === "true",
        }));

        setData({
          ...json,
          subcatdata: uniqueCategories,
          linkdata: uniqueLinkData,
          searchengine: { searchenginedata: initialSelectedEngines },
        });

        setSelectedEngines(initialSelectedEngines);
		 setIsLoading(false);
      })
      .catch((error) => console.error('Error fetching data:', error));
	   setIsLoading(false);  // Ensure loading is set to false even on error
  }, []);
  
   const smoothScrollTo = (targetPosition, duration = 1000) => {
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    let startTime = null;

    const animation = (currentTime) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    const easeInOutQuad = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t + b;
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    };

    requestAnimationFrame(animation);
  };

const scrollToSection = useCallback((categoryName) => {
  if (categoryName === 'All') {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  } else {
    const section = sectionRefs.current[categoryName];
    if (section) {
      // Calculate heights of fixed elements
      const searchBoxHeight = searchBoxRef.current ? searchBoxRef.current.offsetHeight : 0;
      const categorySectionHeight = categorySectionRef.current ? categorySectionRef.current.offsetHeight : 0;
      
      // Total offset is the sum of all fixed elements' heights
      const totalOffset = searchBoxHeight + categorySectionHeight;

      // Get the top position of the section relative to the viewport
      const sectionRect = section.getBoundingClientRect();
      
      // Calculate the target scroll position
      const targetScrollPosition = window.pageYOffset + sectionRect.top - totalOffset;

      // Smooth scroll to the target position
      window.scrollTo({
        top: targetScrollPosition,
        behavior: 'smooth'
      });

      // Optional: Adjust scroll position after a short delay to ensure accuracy
      setTimeout(() => {
        const finalRect = section.getBoundingClientRect();
        if (finalRect.top < totalOffset) {
          window.scrollBy({
            top: finalRect.top - totalOffset,
            behavior: 'smooth'
          });
        }
      }, 500); // Adjust this delay as needed
    }
  }
}, [isSearchClicked]);

const handleCategoryClick = useCallback((categoryName) => {
  clickedCategory.current = categoryName;
  isManuallyScrolling.current = true;

  // Use requestAnimationFrame to ensure state updates are applied
  requestAnimationFrame(() => {
    scrollToSection(categoryName);
  });

  // Delay updating the active category to match the scroll duration
  setTimeout(() => {
    setActiveCategory(categoryName);
  }, 500); // Adjust this value to match your preferred scroll duration

  if (scrollTimeout.current) {
    clearTimeout(scrollTimeout.current);
  }

  scrollTimeout.current = setTimeout(() => {
    isManuallyScrolling.current = false;
    clickedCategory.current = null;
  }, 2000);
}, [scrollToSection]);
  
  

const handleSearch = useCallback((query) => {
  setSearchQuery(query.toLowerCase());
  setActiveCategory('All');

  const lowercaseQuery = query.toLowerCase();

  const filtered = data?.linkdata
    ? data.linkdata.map(category => {
        // Check for exact match with the category name
        const exactCategoryMatch = category.sub_category_name.toLowerCase() === lowercaseQuery;

        if (exactCategoryMatch) {
          // Include all links in the category if the category matches exactly
          return { ...category, links: category.links };
        }

        // Otherwise, filter links based on the search term
        const matchingLinks = category.links.filter(link =>
          link.link_name.toLowerCase().includes(lowercaseQuery)
        );

        // Include the category only if it has matching links
        if (matchingLinks.length > 0) {
          return { ...category, links: matchingLinks };
        }

        // Exclude categories with no matching links
        return null;
      })
      .filter(category => category !== null) // Remove null categories
    : [];

  setFilteredLinks(filtered); // Update the filtered links
}, [data]);








  
  


  const handleSearchClick = () => {
 setIsSearchClicked(true);
  setIsBannerVisible(false);
  };

const handleClearSearch = () => {
  setSearchQuery('');
  setIsSearchClicked(false);
  setIsCategorySticky(false);
  setActiveCategory('All');
  setIsBannerVisible(true);
  setShowEngines(false);
  
  // Smooth scroll to top
  window.scrollTo({ top: 0, behavior: 'smooth' });
  
  // Reset search box position after a short delay
  setTimeout(() => {
    if (searchBoxRef.current) {
      searchBoxRef.current.style.position = 'static';
      searchBoxRef.current.style.top = '0';
    }
  }, 300);
};

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      let query = searchQuery.trim();
      if (query) {
        let searchUrls = selectedEngines
          .filter(engine => engine.selected)
          .map(engine => `${engine.searchengineurl}${query}`)
          .join('/~');
        if (searchUrls) {
          window.open(searchUrls, '_blank');
        }
      }
    }
  };

  const handleEngineSelection = (selectedEngine) => {
    setSelectedEngines(prevEngines =>
      prevEngines.map(engine =>
        engine.searchenginename === selectedEngine.searchenginename
          ? { ...engine, selected: !engine.selected }
          : engine
      )
    );
  };

const filteredLinkData = data?.linkdata
  ? data.linkdata
      .map(category => {
        const lowercaseQuery = searchQuery.toLowerCase();
        const categoryMatches = category.sub_category_name.toLowerCase().includes(lowercaseQuery);
        
        // Filter links within the category based on the search query
        const filteredLinks = category.links.filter(link =>
          link.link_name.toLowerCase().includes(lowercaseQuery)
        );

        // Return the category with filtered links if there's a match
        if (categoryMatches || filteredLinks.length > 0) {
          return { ...category, links: filteredLinks };
        }
        return null;
      })
      .filter(category => category !== null) // Filter out null categories
  : [];
  
  const filteredCategories = data?.subcatdata.filter(category => 
  filteredLinkData.some(filteredCategory => 
    filteredCategory.sub_category_name === category.sub_category_name
  )
);

const updateActiveCategory = useCallback(() => {
  if (isManuallyScrolling.current || clickedCategory.current) return;

  // Get the viewport middle position
  const viewportMiddle = window.scrollY + (window.innerHeight / 3); // Changed from /2 to /3 for better detection
  let newActiveCategory = 'All';
  let smallestDistance = Infinity;

  // Add buffer for top and bottom of sections
  const buffer = 50;  // pixels buffer for better detection

  Object.entries(sectionRefs.current).forEach(([categoryName, ref]) => {
    if (!ref) return;

    const rect = ref.getBoundingClientRect();
    const sectionTop = window.scrollY + rect.top - buffer;
    const sectionBottom = window.scrollY + rect.bottom + buffer;
    const sectionHeight = rect.height;

    // Check if we're within the section bounds
    if (viewportMiddle >= sectionTop && viewportMiddle <= sectionBottom) {
      newActiveCategory = categoryName;
      return;  // Exit the loop if we found a direct match
    }

    // If not within bounds, calculate distance to section
    const distanceToSection = Math.min(
      Math.abs(viewportMiddle - sectionTop),
      Math.abs(viewportMiddle - sectionBottom)
    );

    // For larger sections, give them priority by reducing their effective distance
    const sizeAdjustedDistance = distanceToSection * (1000 / sectionHeight);

    if (sizeAdjustedDistance < smallestDistance) {
      smallestDistance = sizeAdjustedDistance;
      newActiveCategory = categoryName;
    }
  });

  // Only update if actually changed
  if (newActiveCategory !== activeCategory) {
    setActiveCategory(newActiveCategory);
  }
}, [activeCategory]);

  const handleScroll = useCallback(() => {
    if (categorySectionRef.current && bannerRef.current) {
      const scrollPosition = window.scrollY;
      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPercentage = (scrollPosition / totalHeight) * 100;

      const categorySectionTop = categorySectionRef.current.getBoundingClientRect().top;
      const bannerHeight = bannerRef.current.getBoundingClientRect().height;
      const searchHeight = isSearchClicked ? 60 : 0;
      const threshold = bannerHeight + searchHeight;

      if (scrollPosition > lastScrollPosition.current || scrollPercentage > 30) {
        setIsCategorySticky(categorySectionTop <= 0);
        setIsBannerVisible(false);
      } else if (scrollPercentage <= 30) {
        setIsCategorySticky(false);
        setIsBannerVisible(true);
        setIsSearchClicked(false);
      }

      lastScrollPosition.current = scrollPosition;
    }
    
    if (!isManuallyScrolling.current && !clickedCategory.current) {
      debouncedUpdateActiveCategory();
    }
  }, [isSearchClicked]);

 const debouncedUpdateActiveCategory = useCallback(
  debounce(updateActiveCategory, 50), // Reduced from 100ms to 50ms
  [updateActiveCategory]
);
  
 
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }
    };
  }, [handleScroll]);

  if (!data) {
    return <Typography sx={{ textAlign: 'center', mt: 4 }}>Loading...</Typography>;
  }

 return (
    <MainContainer ref={containerRef}>
     <BannerContainer 
		  ref={bannerRef}
		  isVisible={isBannerVisible}
		>
        <Banner banners={data.banner} />
      </BannerContainer>

      <SearchBoxContainer 
        ref={searchBoxRef}
        isSearchClicked={isSearchClicked}
      >
        <SearchEngineSection 
          onSearch={handleSearch} 
          onClick={handleSearchClick} 
          onClear={handleClearSearch}
          onKeyDown={handleSearchKeyDown}
          onEngineSelect={handleEngineSelection}
          isSticky={isSearchClicked} 
          searchEngines={selectedEngines}
          showEngines={showEngines}
          searchValue={searchQuery}
        />
      </SearchBoxContainer>

      {!showEngines && (
        <CategorySectionContainer 
          ref={categorySectionRef}
          isSticky={isCategorySticky}
          isSearchClicked={isSearchClicked}
        >
          <CategorySection
  categories={[{ subcat_id: 'all', sub_category_name: 'All' }, ...filteredCategories]}
            onCategoryClick={handleCategoryClick}
            activeCategory={activeCategory}
            isSticky={isCategorySticky}
            isSearchClicked={isSearchClicked}
            isLoading={isLoading}
          />
        </CategorySectionContainer>
      )}

      {showEngines ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          {selectedEngines.map(engine => (
            <Box key={engine.searchenginename} sx={{ textAlign: 'center', mx: 2 }}>
              {/* Add content for search engines here if needed */}
            </Box>
          ))}
        </Box>
      ) : (
        <LinksSectionContainer>
 <LinksSection
  linkData={filteredLinks} // Updated variable name
  activeCategory={activeCategory}
  sectionRefs={sectionRefs}
/>


        </LinksSectionContainer>
      )}

      <FooterComponent />
    </MainContainer>
  );
};

export default NearMePage;