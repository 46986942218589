import React, { useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { colors } from '../../../theme-styles';

const Header = ({ images, badges }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loading, setLoading] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
      setLoading(true);
    },
    afterChange: (current) => {
      setLoading(false);
    },
    customPaging: (i) => (
      <div
        style={{
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          background: i === currentSlide ? colors.primary : colors.alternate,
          transition: 'all 0.3s ease',
        }}
      />
    ),
  };

 return (
    <Box sx={{ position: 'relative', mb: 2 }}>
      {images && images.length > 0 ? (
        images.length === 1 ? (
          <img 
            src={images[0]} 
            alt="Business Image" 
            style={{ 
              width: '100%', 
              height: '300px', 
              objectFit: 'cover',
            }} 
          />
        ) : (
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index}>
                <img 
                  src={image} 
                  alt={`Business Image ${index + 1}`} 
                  style={{ 
                    width: '100%', 
                    height: '300px', 
                    objectFit: 'cover',
					borderRadius: '15px'
                  }} 
                />
              </div>
            ))}
          </Slider>
        )
      ) : (
        <Box 
          sx={{ 
            width: '100%', 
            height: '300px', 
            backgroundColor: colors.alternate,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        />
      )}
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CircularProgress size={30} sx={{ color: colors.primary }} />
        </Box>
      )}
	  
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        mt: 2, 
        position: 'absolute', 
        bottom: -50, 
        left: 0, 
        right: 0 
      }}>
        {badges && badges.map((badge, index) => (
          <img 
            key={index}
            src={badge.badge_url} 
            alt={badge.badge_icon} 
            style={{ 
              width: '100px', 
              height: '100px', 
              objectFit: 'contain',
              margin: '0 8px'
            }} 
          />
        ))}
      </Box>
    </Box>
  );
};

export default Header;