import React, { useState, useEffect } from 'react';
import { 
  Typography, Button, Rating, Box, CircularProgress, 
  List, ListItem, ListItemText, Divider, Slider 
} from '@mui/material';
//import { EnhancedCard, ContentWrapper, colors } from '../styles';
import { styled } from '@mui/material/styles';
import { EnhancedCard, ContentWrapper, colors, typography} from '../../../theme-styles';

const ReviewContainer = styled(Box)(({ theme }) => ({
  maxHeight: '400px',
  overflowY: 'auto',
  padding: theme.spacing(2),
  backgroundColor: '#f5f5f5',
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(2),
}));

const RatingCountContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const RatingsAndReviews = ({ rating, ratingCount, listingId }) => {
  const [reviews, setReviews] = useState([]);
  const [ratingsCount, setRatingsCount] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const fetchReviews = async (isInitial = false) => {
    try {
      if (isInitial) {
        setLoading(true);
      } else {
        setLoadingMore(true);
      }
      
      let url = isInitial 
        ? `https://developer.2il.org/Appdev/jsonapi/5best/reviewapi.php?lid=${listingId}`
        : `https://developer.2il.org/Appdev/jsonapi/5best/reviewapimore.php?lid=${listingId}&page=${page}`;
      
      const response = await fetch(url);
      const data = await response.text();
      
      if (data === "No reviews found") {
        if (isInitial) {
          setReviews([]);
          setRatingsCount([]);
          setTotalCount(0);
        }
        setHasMore(false);
      } else {
        const jsonData = JSON.parse(data);
        if (isInitial) {
          setReviews(jsonData.reviewdata);
          setRatingsCount(jsonData.ratings_count);
          setTotalCount(jsonData.total_count);
        } else {
          setReviews(prevReviews => [...prevReviews, ...jsonData.reviewdata]);
        }
        setHasMore(jsonData.reviewdata.length === 40);
      }
    } catch (err) {
     // setError("Failed to fetch reviews");
    } finally {
      if (isInitial) {
        setLoading(false);
      } else {
        setLoadingMore(false);
      }
    }
  };

  useEffect(() => {
    fetchReviews(true);
  }, [listingId]);

  const handleShowMore = () => {
    setPage(prevPage => prevPage + 1);
    fetchReviews();
  };

  return (
    
      <ContentWrapper sx={{ padding:'0px',boxShadaow: 'none' }}>
        <Typography variant="h6" component="h3" gutterBottom sx={{ ...typography.h5, color: colors.primary,fontSize: '18px', fontWeight: 'bold' }}>Ratings and Reviews</Typography>
        <Box display="flex" alignItems="center" mb={2}>
          <Rating value={parseFloat(rating)} readOnly precision={0.1} size="large" />
          <Typography variant="body1" ml={1} color={colors.text} fontWeight="bold">({ratingCount} votes)</Typography>
        </Box>
        <Button 
          variant="contained" 
          sx={{ 
            bgcolor: colors.accent, 
            color: '#ffffff',
            '&:hover': { 
              bgcolor: colors.secondary,
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            },
            transition: 'all 0.3s ease-in-out',
            fontWeight: 'bold',
            padding: '10px 20px',
          }}
        >
          Write a review
        </Button>

        {loading ? (
          <Box mt={2} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error" mt={2}>{error}</Typography>
        ) : reviews.length === 0 ? (
          <Typography mt={2}>No reviews found</Typography>
        ) : (
          <>
            <RatingCountContainer>
              <Box flexGrow={1}>
                {ratingsCount.map(({ rating, count }) => (
                  <Box key={rating} display="flex" alignItems="center" mb={1}>
                    <Rating value={rating} readOnly size="small" />
                    <Slider
                      value={count}
                      max={totalCount}
                      disabled
                      sx={{ mx: 2, flexGrow: 1 }}
                    />
                    <Typography variant="body2">{count}</Typography>
                  </Box>
                ))}
              </Box>
            </RatingCountContainer>
            
            <ReviewContainer>
              <List>
                {reviews.map((review, index) => (
                  <React.Fragment key={review.review_id}>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary={
                          <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="subtitle1">{review.reviewer_name}</Typography>
                            <Rating value={parseInt(review.rating)} readOnly size="small" />
                          </Box>
                        }
                        secondary={
                          <>
                            <Typography component="span" variant="body2" color="text.primary">
                              {new Date(review.review_datetime).toLocaleDateString()}
                            </Typography>
                            {" — "}{review.review_comment}
                          </>
                        }
                      />
                    </ListItem>
                    {index < reviews.length - 1 && <Divider variant="inset" component="li" />}
                  </React.Fragment>
                ))}
              </List>
            </ReviewContainer>
            
            {hasMore && (
              <Box mt={2} display="flex" justifyContent="center">
                {loadingMore ? (
                  <CircularProgress />
                ) : (
                  <Button 
                    variant="contained" 
                    onClick={handleShowMore}
                  >
                    Show More Reviews
                  </Button>
                )}
              </Box>
            )}
          </>
        )}
      </ContentWrapper>
   
  );
};

export default RatingsAndReviews;