import React, { useState, useRef, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Button, Box, Rating, Snackbar, Modal, IconButton } from '@mui/material';
import Slider from 'react-slick';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PersonIcon from '@mui/icons-material/Person';
import RateReviewIcon from '@mui/icons-material/RateReview';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CollectionsIcon from '@mui/icons-material/Collections';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { EnhancedCard, ContentWrapper, colors, typography } from '../../theme-styles';

const SliderImageWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  '&:nth-of-type(1) .slick-active img': {
    width: '100%',  // Apply 90% width to the first image when active
    height: '200px',
    objectFit: 'contain',  // Ensures the image scales down while maintaining its aspect ratio
    transition: 'width 0.5s ease',
	paddingRight: '10px'
  },
  '& img': {
    width: '100%',
    height: '200px',
    objectFit: 'cover',  // All other images should cover the container
    borderRadius: '8px',
	paddingRight: '10px'
   
  },
}));


// Styled component for a responsive modal without 3D effect
const ResponsiveModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: 'none',  // Remove the border
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2),
  width: '90%',   // Make the modal responsive by using a percentage width
  maxWidth: '600px',  // Set a max width for larger screens
  borderRadius: '8px',  // Slight border radius for smoother edges
  [theme.breakpoints.down('sm')]: {
    width: '95%',  // For small screens, make it occupy almost full width
  },
}));


const ReviewButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-3px) scale(1.05)',
    boxShadow: '0 5px 10px 2px rgba(255, 105, 135, .5)',
  },
}));

const IconOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 10,
  left: 10,
  right: 10,
  display: 'flex',
  justifyContent: 'space-between',
  pointerEvents: 'none',
  zIndex: 1,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  pointerEvents: 'auto',
}));

const Modal3D = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));



const ListingCard = ({ 
  listingData, 
  handleClick, 
  settings, 
  badgeIconStyle, 
  buttonStyle, 
  callButtonStyle, 
  isLoggedIn, 
  onOpenEnquiry,
  calculationData,
  extCheckData,
  allowRedirect
  
}) => {
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const showCallButton = listingData.contactinfo.is_call === "true";
  const showChatButton = listingData.contactinfo.is_whatsapp === "true";
  const isAd = listingData.is_ad === true;
  const hasBadge = listingData.badgeinfo && listingData.badgeinfo.length > 0;
  const hasGallery = listingData.listdata.is_microsite;
  const hasVideo = listingData.listdata.promotional_video_URL;

   const images = listingData.listdata 
    ? [listingData.listdata.sub_list_image_1, listingData.listdata.sub_list_image_2, listingData.listdata.sub_list_image_3]
        .filter(img => img && img.trim() !== '') // Filter out invalid or empty images
    : []; // Fallback to an empty array if listingData or listdata is undefined

  const sliderSettings = {
    ...settings,
    dots: true,
    infinite: images.length > 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: images.length > 1,
    autoplaySpeed: 3000,
  };
  
  const sliderSettings1 = {
    dots: true,
    infinite: images.length > 1, // Only enable infinite if there are more than one image
    slidesToShow: 1, // Always show one image at a time
    slidesToScroll: 1,
    autoplay: images.length > 1, // Autoplay only if more than one image
    autoplaySpeed: 3000,
    centerMode: images.length === 1, // Only center if there's one image
    centerPadding: images.length === 1 ? '0px' : '50px', // Remove padding if there's only one image, else use 50px
  };

  const handleAction = (action) => {
    handleClick(listingData, action);
  };

 const handleViewProfile = (event) => {
  event.stopPropagation();
  if (allowRedirect === "yes") {
    navigate(`/profile/${listingData.listdata.listing_id}`);
  }
};

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

 const handleCardClick = (event) => {
  if (allowRedirect === "yes" && !event.target.closest('button')) {
    navigate(`/profile/${listingData.listdata.listing_id}`);
  }
};
   
  const handleReviewsClick = () => {
    navigate(`/reviews/${listingData.listdata.listing_id}`);
  };

  const handleGalleryClick = (e) => {
    e.stopPropagation();
    setModalContent('gallery');
    setModalOpen(true);
  };

  const handleVideoClick = (e) => {
    e.stopPropagation();
    setModalContent('video');
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalContent(null);
  };
  
  const [rotation, setRotation] = useState({ x: 0, y: 0 });
  const modalRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!modalRef.current) return;
      const { left, top, width, height } = modalRef.current.getBoundingClientRect();
      const x = (e.clientX - left) / width;
      const y = (e.clientY - top) / height;
      
      // Adjust the multiplier for more pronounced effect
      const rotationX = (0.5 - y) * 20; // Invert Y-axis for natural movement
      const rotationY = (x - 0.5) * 20;
      
      setRotation({ x: rotationX, y: rotationY });
    };

    const handleMouseLeave = () => {
      setRotation({ x: 0, y: 0 });
    };

    const modalElement = modalRef.current;
    if (modalElement) {
      modalElement.addEventListener('mousemove', handleMouseMove);
      modalElement.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener('mousemove', handleMouseMove);
        modalElement.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, []);

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card 
        sx={{ 
          boxShadow: 3, 
          transition: 'transform 0.5s', 
          '&:hover': { transform: 'scale3d(1.05, 1.05, 1.05)' },
          cursor: allowRedirect === "yes" ? 'pointer' : 'default',
          position: 'relative'
        }}
        onClick={handleCardClick}
      >
        <Box sx={{ background: 'transparent', padding: '10px', position: 'relative' }}>
          {images.length > 1 ? (
            <Box sx={{ position: 'relative' }}>
              <Slider {...sliderSettings}>
      {images.map((img, index) => (
        <SliderImageWrapper key={index}>
          <img src={img} alt={`Slide ${index + 1}`} />
        </SliderImageWrapper>
      ))}
    </Slider>
              <IconOverlay>
                {hasGallery && (
                  <StyledIconButton onClick={handleGalleryClick}>
                    <CollectionsIcon />
                  </StyledIconButton>
                )}
                {hasVideo && (
                  <StyledIconButton 
                    onClick={handleVideoClick}
                    sx={{ marginLeft: 'auto' }}
                  >
                    <YouTubeIcon />
                  </StyledIconButton>
                )}
              </IconOverlay>
            </Box>
          ) : (
                       <Box sx={{ position: 'relative' }}>
              <Slider {...sliderSettings1}>
      {images.map((img, index) => (
        <SliderImageWrapper key={index}>
          <img src={img} alt={`Slide ${index + 1}`} />
        </SliderImageWrapper>
      ))}
    </Slider>
              <IconOverlay>
                {hasGallery && (
                  <StyledIconButton onClick={handleGalleryClick}>
                    <CollectionsIcon />
                  </StyledIconButton>
                )}
                {hasVideo && (
                  <StyledIconButton 
                    onClick={handleVideoClick}
                    sx={{ marginLeft: 'auto' }}
                  >
                    <YouTubeIcon />
                  </StyledIconButton>
                )}
              </IconOverlay>
            </Box>
          )}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            marginTop: 2,
            flexWrap: 'wrap'
          }}>
            <Box sx={{ 
			  display: 'flex', 
			  gap: 1, // Reduced gap between badges
			  flexGrow: 1, 
			  justifyContent: 'center',
			  alignItems: 'center', // Ensure vertical alignment
			  flexWrap: 'wrap', // Allow wrapping for multiple badges
			  marginTop: 1, // Reduced top margin
			  marginBottom: 1 // Added bottom margin for spacing
			}}>
			  {listingData.badgeinfo.map((badge, index) => (
				<img 
				  key={index} 
				  src={badge.badge_url} 
				  alt="Badge" 
				  style={{
					...badgeIconStyle,
					width: '80px', // Ensure the width is applied here as well
					objectFit: 'contain' // Maintain aspect ratio
				  }} 
				/>
			  ))}
			</Box>
            {isAd && (
              <Box
                sx={{
                  backgroundColor: '#007bff',
                  color: 'white',
                  padding: '2px 8px',
                  borderRadius: '4px',
                  fontSize: '0.75rem',
                  fontWeight: 'bold',
                  marginLeft: 'auto',
                  boxShadow: '0 0 10px #007bff, 0 0 20px #007bff, 0 0 30px #007bff',
                  animation: 'glow 1.5s ease-in-out infinite alternate',
                  '@keyframes glow': {
                    from: {
                      boxShadow: '0 0 5px #007bff, 0 0 10px #007bff, 0 0 15px #007bff',
                    },
                    to: {
                      boxShadow: '0 0 10px #007bff, 0 0 20px #007bff, 0 0 30px #007bff',
                    },
                  },
                }}
              >
                Ad
              </Box>
            )}
          </Box>
        </Box>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{ color:'#CF5F39' }}>
            {listingData.listdata.business_name}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
            <Rating name="read-only" value={listingData.listdata.sub_rating || 0} readOnly precision={0.5} />
            <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
              {listingData.listdata.rating_count} reviews
            </Typography>
          </Box>
          <Typography variant="body2" color="text.secondary">
            {listingData.listdata.address_line_1}, {listingData.listdata.city_name}, {listingData.listdata.state_name} - {listingData.listdata.address_pincode}, {listingData.listdata.country_name}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
            {showCallButton && (
             <Button 
			  onClick={() => handleAction('call')}
			  variant="contained" 
			  color="primary" 
			  startIcon={<PhoneIcon />} 
			  sx={{ 
				...callButtonStyle, 
				backgroundColor: colors.callbuttonbg, // replace with your desired color
				
			  }}
			>
			  Call
			</Button>
            )}
            {showChatButton && (
              <Button 
                onClick={() => handleAction('chat')}
                variant="contained" 
                color="success" 
                startIcon={<WhatsAppIcon />}  
				 sx={{ 
				...buttonStyle, 
				backgroundColor: colors.whatsappbuttonbg, // replace with your desired color
				
			  }}
              >
                Chat
              </Button>
            )}
            {allowRedirect === "yes" && ( 
              <Button 
                onClick={handleViewProfile}
                variant="contained" 
                color="primary"
                startIcon={<PersonIcon />}
               sx={{ 
				...buttonStyle, 
				backgroundColor: colors.commonbtnbg, 
				color: colors.commonbtntextcolor
				
			  }}
              >
                View Profile
              </Button>
            )}
          </Box>
          <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}>
            <ReviewButton
              onClick={handleReviewsClick}
              startIcon={<RateReviewIcon />}
              fullWidth
            >
              Read and Write Reviews
            </ReviewButton>
          </Box>
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Sorry this listing is not verified."
      />
<ResponsiveModal
  open={modalOpen}
  onClose={handleCloseModal}
  aria-labelledby="responsive-modal-title"
  aria-describedby="responsive-modal-description"
>
  <ModalContent>
    {modalContent === 'gallery' && listingData.listdata.is_microsite && (
      <iframe
        src={listingData.listdata.is_microsite}
        title="Gallery"
        width="100%"  // Set iframe to full width
        height="100%"  // Set iframe to full height
        style={{ border: 'none', minHeight: '300px' }}  // Ensure iframe is responsive and has minimum height
      />
    )}
    {modalContent === 'video' && listingData.listdata.promotional_video_URL && (
      <iframe
        src={listingData.listdata.promotional_video_URL}
        title="YouTube video player"
        width="100%"  // Full width on all screen sizes
        height="300px"  // Minimum height for mobile, adjust for other screens if necessary
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ borderRadius: '8px' }}  // Rounded corners for a cleaner look
      />
    )}
  </ModalContent>
</ResponsiveModal>

    </Grid>
  );
};

export default ListingCard;