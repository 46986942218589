import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Typography, Grid, Chip, Paper } from '@mui/material';
import axios from 'axios';

const BestLocationsPage = () => {
    const { subcategory } = useParams();
    const [locations, setLocations] = useState([]);
    const [groupedLocations, setGroupedLocations] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://developer.2il.org/Appdev/jsonapi/5best/get_location_by_subcategory.php?subcat=${subcategory}`);
                const data = response.data;

                // Group by state
                const grouped = data.reduce((acc, location) => {
                    const { state } = location;
                    if (!acc[state]) {
                        acc[state] = [];
                    }
                    acc[state].push(location);
                    return acc;
                }, {});

                setGroupedLocations(grouped);
                setLocations(data);
            } catch (error) {
                console.error('Error  fetching locations:', error);
            }
        };

        fetchData();
    }, [subcategory]);

    
    return (      
        <Container style={{ marginTop: '30px' }}>
            <Typography variant="h4" align="center" gutterBottom>
                Best {subcategory} in India
            </Typography>

            {Object.keys(groupedLocations).map((state, index) => (
                <Paper key={index} elevation={3} style={{ marginBottom: '20px', padding: '20px' }}>
                    <Typography variant="h5" gutterBottom>{state}</Typography>
                    <Grid container spacing={2}>
                        {groupedLocations[state].map((location, idx) => (
                            <Grid item key={idx}>
                                <Link to={`/${location.subcat_urlslug}/${location.city_urlslug}`} style={{ textDecoration: 'none' }}>
                                    <Chip 
                                        label={location.city} 
                                        style={{ backgroundColor: '#ffca28', color: '#000' }} 
                                        clickable 
                                    />
                                </Link>
                            </Grid>
                        ))}
                    </Grid>
                </Paper>
            ))}
        </Container>
    );
};

export default BestLocationsPage;
