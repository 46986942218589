import React from 'react';
import { Typography, Box } from '@mui/material';
import { LocationOn } from '@mui/icons-material'; 
import { colors, typography } from '../../../theme-styles';

const Location = ({ mapUrl, address }) => {
  return (
    <Box>
      {mapUrl && (
        <>
          <Typography
            variant="h5"
            gutterBottom
            display="flex"
            alignItems="center"
            sx={{ ...typography.h5, color: colors.primary, fontSize: '18px', fontWeight: 'bold' }}
          >
            <LocationOn sx={{ mr: 1, color: colors.secondary }} /> Location
          </Typography>
          <Box
            sx={{ width: '100%', height: '300px', mb: 2, overflow: 'hidden', borderRadius: '12px' }}
          >
            <iframe
              src={mapUrl}
              width="100%"
              height="300"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Business Location"
            ></iframe>
          </Box>
        </>
      )}
     
    </Box>
  );
};

export default Location;
