import React from 'react';
import { Grid, Card, CardMedia, CardContent, Typography, Box, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { colors } from '../../theme-styles';

const LinksSection = ({ linkData, activeCategory, sectionRefs }) => {
  const theme = useTheme();

  return (
    <Box sx={{ mb: 4, width: '100%', }}>
      {linkData.map((category) => (
        <Paper className='cat-cardbox'  
          key={category.subcat_id}
          ref={(el) => {
            if (el) {
              sectionRefs.current[category.sub_category_name] = el;
            }
          }}
          elevation={category.sub_category_name === activeCategory ? 8 : 1}
          sx={{
            mb: 5,    
            p: 2,
			
            transition: theme.transitions.create(['box-shadow', 'transform', 'background-color'], {
              duration: theme.transitions.duration.standard,
            }),
            transform: category.sub_category_name === activeCategory ? 'scale(1.02)' : 'scale(1)',
            backgroundColor: category.sub_category_name === activeCategory 
			  ? colors.sectionActive  // Blue color for active sections
			  : colors.linkSectionBackground,
            '&:hover': {
              transform: 'scale(1.01)',
            },
          }}
        >
          <Typography sx={{ color: category.sub_category_name === activeCategory 
			  ? colors.accent4  // Blue color for active sections
			  : colors.secondary, fontWeight: 'bold' }} variant="h6" gutterBottom>{category.sub_category_name}</Typography>
          <Grid container spacing={1} sx={{ width: '100%', margin: 0, background: 'transparent', }}>
            {category.links.map((link) => (
              <Grid 
                item 
                xs={3}
                sm={3} 
                md={4} 
                lg={3} 
                xl={2} 
                key={link.link_id} 
                sx={{ 
                  width: '25%',
                  padding: '0px',
                  boxSizing: 'border-box',
				  paddingTop: '8px',
				  paddingLeft: '5px'
                }}
              >
                <a 
                  href={link.link_URL} 
                  className='categoty-link-box'
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ 
                    textDecoration: 'none', 
                    color: 'inherit',
                    display: 'block',
                    height: '100%'
                  }}
                >
                  <Card sx={{ 
                    textAlign: 'center',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    background: 'transparent',
                  }} className='grid-item'>
                    <CardMedia
                      component="img"
                      image={link.link_image}
                      alt={link.link_name}
                      sx={{ 
                        width: 60,
                        height: 60, 
                        margin: 'auto',  
                        pt: 1, 
                        borderRadius: '50%', 
                        background: colors.secondaryBackground,
                        border: `2px solid ${colors.secondaryBackground}`,
                        padding: '2px',
                      }}
                    />
                    <CardContent sx={{ p: 0, paddingTop: '5px' }} className='cardTitle'> 
                      <Typography variant="body2" sx={{ fontSize: '0.75rem',lineHeight:'1.2',color: category.sub_category_name === activeCategory 
			  ? colors.accent4  // Blue color for active sections
			  : '#000', }}>  
                        {link.link_name}
                      </Typography>
                    </CardContent>
                  </Card>
                </a>
              </Grid>
            ))}
          </Grid>
        </Paper>
      ))}
    </Box>
  );
};

export default LinksSection;