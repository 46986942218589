import React, { useEffect, useState, useRef, useCallback, useMemo, Suspense, lazy } from "react";
import {
	Avatar,
	AppBar,
	Badge,
	Toolbar,
  Container,
  TextField,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CircularProgress,
  IconButton,
  Collapse,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Divider
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from '@mui/icons-material/Search';
import { styled, keyframes } from '@mui/material/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import Lottie from "lottie-react";
import loaderAnimation from "./loader-animation.json";
import { openDB } from 'idb';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
//import HeaderCarousel from './HeaderCarousel';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import { debounce } from 'lodash'; // Make sure to install lodash if you haven't already
import ShareIcon from '@mui/icons-material/Share';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';  
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark'; // General Collection Icon
import ErrorBoundary from './ErrorBoundary'; 
import { throttle } from 'lodash';




// Debounce utility function
const debounceSearch = debounce((func) => func(), 300);

//const throttledLoadMore = useCallback(throttle(loadMore, 2000), [currentPage, hasMore, filtersApplied]);

const LazyHeaderCarousel = lazy(() => import('./HeaderCarousel'));

const SearchEngineIcon = styled('div')(({ theme, isSelected }) => ({
  width: 50,
  height: 50,
  borderRadius: '50%',
  cursor: 'pointer',
  margin: '0 10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.3s ease',
  backgroundColor: isSelected ? 'red' : 'gray',
  '&:hover': {
    transform: 'scale(1.1)',
  },
  '& img': {
    width: '60%',
    height: '60%',
    objectFit: 'contain',
  },
}));


const StickySearchBar = styled("div")(({ theme, isSticky }) => ({
  position: isSticky ? "fixed" : "sticky",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
  backgroundColor: "#fff",
  padding: "10px",
  boxShadow: isSticky ? "0 2px 5px rgba(0,0,0,0.1)" : "none",
  transition: "all 0.3s ease",
  width: '100%',
}));

const SearchBarPlaceholder = styled("div")(({ isSticky }) => ({
  height: isSticky ? '56px' : '0', // Adjust this value based on your search bar's height
  transition: 'height 0.3s ease',
}));



// Loader style using Box from MUI
const Loader = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
});

// Favicon container and style
const FaviconContainer = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  gap: "5px",
  marginBottom: "10px",
});

const Favicon = styled("img")({
  width: "16px",
  height: "16px",
  objectFit: "contain",
});

// Function to load a Lottie animation as a loader

const LottieLoader = () => (
  <Loader>
    <Lottie
      animationData={loaderAnimation}
      style={{ width: 100, height: 100 }}
      loop={true}
      autoplay={true}
    />
  </Loader>
);

/*
 const LottieLoader = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
      <Lottie
        animationData={loaderAnimation}
        style={{ width: 100, height: 100, filter: 'brightness(0) invert(1)' }}
        loop={true}
        autoplay={true}
      />
    </Box>
  );
  */

// Function to generate favicon URL from a given URL
const getFaviconUrl = (url) => {
  try {
    const domain = new URL(url).hostname;
    return `https://www.google.com/s2/favicons?domain=${domain}&size=64`;
  } catch (error) {
    console.error("Invalid URL:", url);
    return null;
  }
};

const styles = `
  .sticky-header {
    transform-origin: top;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) !important;
  }

  .sticky-header.MuiAppBar-root {
    background-color: #FFFFFF !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15) !important;
    width: 110% !important;
    padding: 6px 12px !important;
    border-radius: 0 !important;
    margin-left: -5% !important;
  }

  .sticky-header .MuiToolbar-root {
    padding: 0 16px !important;
  }
`;



// Styled "My Collections" Button with Constant Animation
const MyCollectionsButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
  borderRadius: '50px',
  border: 0,
  color: 'white',
  padding: '10px 30px',
  fontSize: '16px',
  fontWeight: 'bold',
  boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  animation: `${pulse} 2s infinite`, // Constant pulsing animation
  transition: 'border-radius 0.5s ease',
  '&:hover': {
    borderRadius: '25px',  // Rounded edge animation on hover
  },
}));


const textGlow = keyframes`
  0%, 100% { text-shadow: 0 0 5px #ffffff, 0 0 10px #64b5f6; }
  50% { text-shadow: 0 0 10px #ffffff, 0 0 20px #2196F3; }
`;


const pulse = keyframes`
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.05); }
`;

const shimmer = keyframes`
  0% { background-position: -200%; }
  100% { background-position: 200%; }
`;

const particleAnimation = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
`;

// Custom styled card with parallax and 3D perspective effect
const StyledCard = styled(Card)({
  borderRadius: '20px',
  boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.2)',
  background: 'rgba(255, 255, 255, 0.75)',
  backdropFilter: 'blur(12px)',
  perspective: '1000px',
  transition: 'transform 0.4s ease, box-shadow 0.4s ease',
  overflow: 'hidden',
  '&:hover': {
    boxShadow: '0px 15px 50px rgba(0, 0, 0, 0.3)',
    transform: 'rotateY(8deg) scale(1.1)',
  },
  '&:hover .card-media': {
    transform: 'scale(1.1)',
    filter: 'brightness(1.2)',
  },
});

// Particle animation effect for header background



// Custom button with advanced effects
const AnimatedButton = styled(Button)({
  background: 'linear-gradient(90deg, #42a5f5, #64b5f6, #42a5f5)',
  backgroundSize: '200% 200%',
  color: '#FFFFFF',
  padding: '10px 25px',
  borderRadius: '25px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '1rem',
  animation: `${shimmer} 3s infinite linear, ${pulse} 4s infinite ease-in-out`,
  '&:hover': {
    transform: 'scale(1.1)',
    boxShadow: '0 10px 25px rgba(100, 181, 246, 0.6)',
  },
});

const StyledDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    borderRadius: '20px',
    backdropFilter: 'blur(10px)',
    background: 'rgba(255, 255, 255, 0.75)',
    boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.1)',
  },
});

// Enhanced button with better visual effects
const StyledButton = styled(Button)({
  color: '#FFFFFF',
  background: 'linear-gradient(90deg, #2196F3, #21CBF3)',
  padding: '8px 24px',
  borderRadius: '25px',
  fontWeight: 'bold',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0px 5px 20px rgba(33, 203, 243, 0.5)',
  },
});

// IconButton for the top-right close button
const CloseButton = styled(IconButton)({
  position: 'absolute',
  right: 16,
  top: 16,
  color: '#ffffff',
  backgroundColor: '#ff4081',
  '&:hover': {
    backgroundColor: '#ff79b0',
    transform: 'scale(1.1)',
  },
  transition: 'all 0.3s ease',
});



// Styled button for snowfall effect and text glow
const GlowingButton = styled(Button)({
  color: '#FFFFFF',
  background: 'linear-gradient(90deg, #42a5f5, #64b5f6)',
  padding: '10px 25px',
  borderRadius: '25px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '1rem',
  position: 'relative',
  overflow: 'hidden',
  textShadow: '0 0 12px rgba(255, 255, 255, 1), 0 0 24px rgba(100, 181, 246, 0.8), 0 0 36px rgba(100, 181, 246, 0.6)', // Enhanced Text Glow Effect
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 5px 20px rgba(100, 181, 246, 0.5)',
  },
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
});


// Update to ensure that the spacer appears immediately
const PageSpacer = ({ pageNumber }) => (
  <Grid item xs={12}>
    <Box
      sx={{
        width: '100%',
        height: '56px', // Explicitly set height to ensure it takes up space immediately
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        py: 3,
        my: 2,
        backgroundColor: '#f5f5f5', // Light background color to make the spacer visible early
        opacity: 0.8, // Semi-transparent to differentiate the spacer from other components
      }}
    >
    
    </Box>
  </Grid>
);

// Loader at the bottom of the page for infinite scroll
const InfiniteLoader = ({ isLoading }) => (
  <Box
    sx={{
      width: '100%',
      height: '100px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      my: 4,
    }}
  >
    {isLoading && (
      <Lottie
        animationData={loaderAnimation}
        style={{ width: 80, height: 80 }}
        loop={true}
        autoplay={true}
      />
    )}
  </Box>
);



const transitionStyles = {
  transform: 'none',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
};


// Main CollectionsPage component
const CollectionsPage = () => {
  const [collections, setCollections] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [expandedId, setExpandedId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedIds, setExpandedIds] = useState({});
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [isSearching, setIsSearching] = useState(false); // State to track if searching is active
  const [likedItems, setLikedItems] = useState({});
  const [pinnedItems, setPinnedItems] = useState({});
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [likeCounts, setLikeCounts] = useState({});
  const [pinnedCollections, setPinnedCollections] = useState([]);
  const [pinnedCollectionsOpen, setPinnedCollectionsOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [tempSelectedFilters, setTempSelectedFilters] = useState({});
  const [isFiltering, setIsFiltering] = useState(false);
  const [allCollections, setAllCollections] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [filteredBeforeSearch, setFilteredBeforeSearch] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchEngines, setSearchEngines] = useState([]);
  const [selectedEngines, setSelectedEngines] = useState([]);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const searchBarRef = useRef(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [expandedItemId, setExpandedItemId] = useState(null);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  
  


  
   useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
  }, []);

  
useEffect(() => {
  let lastScroll = 0;
  const threshold = 50; // Minimum scroll distance before triggering sticky

  const handleScroll = () => {
    const currentScroll = window.scrollY;
    
    if (searchBarRef.current) {
      if (Math.abs(currentScroll - lastScroll) > threshold) {
        setIsSticky(currentScroll > lastScroll && currentScroll > 0);
        lastScroll = currentScroll;
      }
    }
  };

  window.addEventListener('scroll', handleScroll, { passive: true });
  return () => window.removeEventListener('scroll', handleScroll);
}, []);




   


  const handleOpenFilterModal = () => {
    setTempSelectedFilters(selectedFilters);
    setFilterOpen(true);
  };
  
const handleClearSearch = () => {
  setSearchTerm('');
  setIsSearchActive(false);
  setIsSearching(false);
  setCurrentPage(1);
  setFilteredCollections([]);
  
  // Enable infinite scroll again and set hasMore to true
  setHasMore(true);

  // Fetch collections for the first page again
   if (filtersApplied) {
    // If filters are applied, keep using the filterCategory parameter
    let filterCategory = Object.entries(selectedFilters)
      .flatMap(([category, subCategories]) => 
        Object.keys(subCategories).filter(subCatId => subCategories[subCatId])
      )
      .join(",");

    // Fetch collections while retaining the applied filter
    fetchCollections(`https://developer.2il.org/Appdev/jsonapi/collectionsapi.php?filterCategory=${filterCategory}`, true);
  } else {
    // Fetch collections without any filters or search if no filters are applied
    fetchCollections("https://developer.2il.org/Appdev/jsonapi/collectionsapi.php?page=1", true);
  }
};



		
		
  
useEffect(() => {
  const initializeDatabase = async () => {
    try {
      // Increment the version to 3 to ensure all object stores are created properly
      const db = await openDB('collectionsDB', 3, {
        upgrade(db) {
          if (!db.objectStoreNames.contains('likes')) {
            console.log('Creating the likes object store...');
            db.createObjectStore('likes', { keyPath: 'dataId' });
          }
          if (!db.objectStoreNames.contains('pins')) {
            console.log('Creating the pins object store...');
            db.createObjectStore('pins', { keyPath: 'dataId' });
          }
        },
      });
      console.log('Database initialized successfully with object stores:', db.objectStoreNames);
    } catch (error) {
      console.error('Error initializing the database:', error);
    }
  };

  initializeDatabase();
}, []);


useEffect(() => {
  const fetchPinnedItemsFromDB = async () => {
    try {
      const pinnedDataIds = await getPinnedCollections();
      const newPinnedItems = pinnedDataIds.reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {});
      setPinnedItems(newPinnedItems);
    } catch (error) {
      console.error('Error fetching pinned items from IndexedDB:', error);
    }
  };

  fetchPinnedItemsFromDB();
}, []);

useEffect(() => {
  const fetchLikedItemsFromDB = async () => {
    try {
      const db = await openDB('collectionsDB', 3);
      const likes = await db.getAll('likes');
      const newLikedItems = likes.reduce((acc, { dataId }) => {
        acc[dataId] = true;
        return acc;
      }, {});
      setLikedItems(newLikedItems);
    } catch (error) {
      console.error('Error fetching liked items from IndexedDB:', error);
    }
  };

  fetchLikedItemsFromDB();
}, []);

 


  // Handle Like Button Click
const handleLikeClick = async (dataId) => {
  const db = await openDB('collectionsDB', 3);
  if (likedItems[dataId]) {
    const currentLikeCount = likeCounts[dataId] || 0;
    await db.delete('likes', dataId);
    setLikedItems((prev) => ({ ...prev, [dataId]: false }));
    setLikeCounts((prev) => ({ ...prev, [dataId]: Math.max(0, currentLikeCount - 1) }));
  } else {
    const currentLikeCount = likeCounts[dataId] || 0;
    await db.put('likes', { dataId, count: currentLikeCount + 1 });
    setLikedItems((prev) => ({ ...prev, [dataId]: true }));
    setLikeCounts((prev) => ({ ...prev, [dataId]: currentLikeCount + 1 }));
  }
};




  // Handle Pin Button Click
// Handle Pin Button Click
const handlePinClick = async (dataId) => {
  const db = await openDB('collectionsDB', 3);
  if (pinnedItems[dataId]) {
    await db.delete('pins', dataId);
    setPinnedItems((prev) => ({ ...prev, [dataId]: false }));
    
    // Update snackbar message and open it
    setSnackbarMessage('Item unpinned successfully');
    setSnackbarOpen(true);
  } else {
    await db.put('pins', { dataId });
    setPinnedItems((prev) => ({ ...prev, [dataId]: true }));
    
    // Update snackbar message and open it
    setSnackbarMessage('Item pinned successfully');
    setSnackbarOpen(true);
  }
};



  // Handle Share Click
  const handleShareClick = (dataId) => {
    // This function can open a sharing modal or navigation drawer
    navigator.share ? navigator.share({
      title: 'Check out this collection!',
      text: 'Take a look at this amazing collection!',
      url: `https://yourapp.com/collections/${dataId}`,
    }) : alert("Sharing not supported in this browser.");
  };
  
const handleMyCollectionsClick = async () => {
  // Step 1: Fetch pinned IDs from IndexedDB
  const pinnedDataIds = await getPinnedCollections();
  console.log("Pinned Data IDs fetched:", pinnedDataIds);

  if (pinnedDataIds.length === 0) {
    // If no pinned IDs are found, simply open the modal with no data
    setPinnedCollections([]);
    setPinnedCollectionsOpen(true);
    return;
  }

  try {
    // Step 2: Fetch full details of pinned collections using collectionsapi.php
    const response = await axios.get(`https://developer.2il.org/Appdev/jsonapi/collectionsapi.php?ids=${pinnedDataIds.join(',')}`);
    const fetchedCollections = response.data.collectionsdata || [];

    // Step 3: Enrich and verify data for rendering in the CollectionCard component
    const enrichedCollections = fetchedCollections.map(item => ({
      ...item,
      title: item.title || "Untitled",
      image: item.image || "https://via.placeholder.com/150",
      name: item.name || "Unknown User",
      featured_image: item.featured_image || "https://via.placeholder.com/300x140",
      likeCount: item.likeCount || 0,
    }));

    // Update state with fetched pinned collections
    setPinnedCollections(enrichedCollections);
    console.log("Pinned Collections after API call:", enrichedCollections); // Debugging line to verify data
    setPinnedCollectionsOpen(true);
  } catch (error) {
    console.error("Error fetching pinned collections from API:", error);
    setPinnedCollections([]);
    setPinnedCollectionsOpen(true);
  }
};







  
const getPinnedCollections = async () => {
  const db = await openDB('collectionsDB', 3);
  const pins = await db.getAll('pins');
  console.log("Pins fetched from IndexedDB:", pins); // Debugging line
  const pinnedDataIds = pins.map(pin => pin.dataId);
  return pinnedDataIds;
};


  
 // const classes = useStyles();


 const handleSearch = useCallback((e) => {
  const searchTermValue = e.target.value.trim().toLowerCase();
  setSearchTerm(searchTermValue);
  setIsSearchActive(searchTermValue.length > 0);
  setIsSearching(true);  // Set searching state to true

  debounceSearch(() => {
    setCurrentPage(1);
    setHasMore(false); // Disable infinite scroll during search

    let filterCategory = "";
    if (filtersApplied) {
      filterCategory = Object.entries(selectedFilters)
        .flatMap(([category, subCategories]) => 
          Object.keys(subCategories).filter(subCatId => subCategories[subCatId])
        )
        .join(",");
    }

    if (searchTermValue.length > 0) {
      // Fetch search results without incrementing the page count
      fetchCollections(`https://developer.2il.org/Appdev/jsonapi/collectionsapi.php?filterCategory=${filterCategory}&searchTerm=${searchTermValue}`, true);
    } else {
      handleClearSearch(); // Reset search if search term is empty
    }
  });

  if (e.key === 'Enter') {
    debounceSearch.cancel(); // Cancel any pending debounced searches
    handleExternalSearch();
  }
});



const handleEngineToggle = (engine) => {
  setSelectedEngines(prev => 
    prev.some(e => e.searchenginename === engine.searchenginename)
      ? prev.filter(e => e.searchenginename !== engine.searchenginename)
      : [...prev, engine]
  );
};

const handleExternalSearch = () => {
  if (selectedEngines.length > 0 && searchTerm) {
    const encodedQuery = encodeURIComponent(searchTerm);
    const searchUrls = selectedEngines.map(engine => 
      `${engine.searchengineurl}${encodedQuery}`
    ).join('/~');
    window.open(`collections:${searchUrls}`, '_blank');
  }
};







  // Function to handle card click event
  const handleCardClick = (item, event) => {
    if (event.target.closest(".MuiIconButton-root")) {
      return; // Prevent click propagation if expand button is clicked
    }

    const urls = [];
    for (let i = 1; i <= 10; i++) {
      const url = item[`item${i}`];
      if (url) {
        urls.push(url);
      }
    }

    if (urls.length > 0) {
      const formattedUrls = urls.join("\\~\\");
      window.open(`collections:${formattedUrls}`, "_blank");
    }
  };

const fetchCollections = async (url, shouldClear = false) => {
  if (isLoading) return;

  setIsLoading(true);
  setError(null);

  try {
    console.log('Fetching from URL:', url);
    const response = await axios.get(url);
    console.log('API Response:', response.data);

    // Check if response has the expected structure
    if (!response.data || !response.data.collectionsdata) {
      throw new Error('Invalid data structure received from API');
    }

    const newCollections = response.data.collectionsdata;
    
    // Process collections to ensure all required fields exist
    const processedCollections = newCollections.map(collection => ({
      ...collection,
      data_id: collection.data_id || collection.id || Math.random().toString(),
      title: collection.title || 'Untitled',
      description: collection.description || '',
      image: collection.image || 'https://via.placeholder.com/150',
      featured_image: collection.featured_image || 'https://via.placeholder.com/300x140',
      name: collection.name || 'Unknown User',
      categories: collection.categories || []
    }));

    console.log('Processed Collections:', processedCollections);

    if (shouldClear) {
      setAllCollections(processedCollections);
      setFilteredCollections(processedCollections);
    } else {
      setAllCollections(prev => [...prev, ...processedCollections]);
      setFilteredCollections(prev => [...prev, ...processedCollections]);
    }

    setHasMore(processedCollections.length >= 10);
    setIsLoading(false);

  } catch (error) {
    console.error('Error fetching collections:', error);
    setError('Failed to fetch collections. Please try again.');
    setHasMore(false);
    setIsLoading(false);
  }
};




useEffect(() => {
  const initializeFilters = async () => {
    try {
      const response = await axios.get("https://developer.2il.org/Appdev/jsonapi/collectionsfilter.php");
      const data = response.data.collectionsdata || [];
      
      // Group the filter options by category
      const groupedOptions = data.reduce((acc, item) => {
        if (!acc[item.category_name]) {
          acc[item.category_name] = {};
        }
        acc[item.category_name][item.sub_category_id] = item;
        return acc;
      }, {});

      setFilterOptions(groupedOptions);

      // Initialize filter states
      const initialFilters = Object.keys(groupedOptions).reduce((acc, category) => {
        acc[category] = Object.keys(groupedOptions[category]).reduce((subAcc, id) => {
          subAcc[id] = false;
          return subAcc;
        }, {});
        return acc;
      }, {});

      setSelectedFilters(initialFilters);
      setTempSelectedFilters(initialFilters);
    } catch (error) {
      console.error("Error fetching filter options:", error);
    }
  };

  initializeFilters();
}, []);


useEffect(() => {
  console.log("Fetching initial collections data...");
  fetchCollections("https://developer.2il.org/Appdev/jsonapi/collectionsapi.php?page=1");
  fetchSearchEngines();
}, []);

const fetchSearchEngines = async () => {
  try {
    const response = await axios.get('https://developer.2il.org/Appdev/jsonapi/collectionsapi.php');
    const data = response.data.searchengine.searchenginedata;
    setSearchEngines(data);
    const defaultSelected = data.filter(engine => engine.selected === "true");
    setSelectedEngines(defaultSelected.length > 0 ? defaultSelected : [data.find(e => e.searchenginename === "google")]);
  } catch (error) {
    console.error('Error fetching search engines:', error);
  }
};

  // Function to load more collections on scroll
const loadMore = async () => {
  if (!hasMore || isFetchingMore) return;

  // Show the loader
  setIsFetchingMore(true);

  // Ensure loader is rendered immediately
  requestAnimationFrame(() => {
    setTimeout(async () => {
      try {
        console.log('Loading more items...');
        const nextPage = currentPage + 1;
        setCurrentPage(nextPage);

        let filterCategory = "";
        if (filtersApplied) {
          filterCategory = Object.entries(selectedFilters)
            .flatMap(([category, subCategories]) =>
              Object.keys(subCategories).filter((subCatId) => subCategories[subCatId])
            )
            .join(",");
        }

        const apiUrl = `https://developer.2il.org/Appdev/jsonapi/collectionsapi.php?${
          filtersApplied ? `filterCategory=${filterCategory}&` : ""
        }page=${nextPage}`;

        const response = await axios.get(apiUrl, { timeout: 10000 });

        const newCollections = response.data.collectionsdata || [];
        if (newCollections.length > 0) {
          setAllCollections((prevAll) => [...prevAll, ...newCollections]);
          setFilteredCollections((prevFiltered) => [...prevFiltered, ...newCollections]);
          setHasMore(newCollections.length >= itemsPerPage);
        } else {
          setHasMore(false);
        }
      } catch (error) {
        console.error("Error loading more data:", error);
        setTimeout(loadMore, 5000);
        setError("Failed to load more collections. Retrying...");
      } finally {
        setIsFetchingMore(false);
      }
    }, 0);
  });
};







  
  
  useEffect(() => {
    fetchFilterOptions();
  }, []);

  useEffect(() => {
    if (!isFiltering) {
      setFilteredCollections(collections);
    }
  }, [collections, isFiltering]);



  // Function to handle the expansion of the card to show more information
	/*const handleExpandClick = (dataId) => {
		  setExpandedIds(prevState => ({
			...prevState,
			[dataId]: !prevState[dataId]
		  }));
		};
	*/	
 const handleFilterChange = (category, subCategoryId) => {
  setTempSelectedFilters(prev => ({
    ...prev,
    [category]: {
      ...prev[category],
      [subCategoryId]: !prev[category]?.[subCategoryId]
    }
  }));
};


  // Filtering collections based on search term
		useEffect(() => {
		  const filtered = collections.filter((collection) =>
			collection.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
			(collection.description &&
			  collection.description.toLowerCase().includes(searchTerm.toLowerCase()))
		  );
		  setFilteredCollections(filtered);
		}, [collections, searchTerm]);
		
		
		useEffect(() => {
           fetchFilterOptions();
        }, []);
		
// Modified filter option fetching
const fetchFilterOptions = async () => {
  try {
    const response = await axios.get("https://developer.2il.org/Appdev/jsonapi/collectionsfilter.php");
    if (response.data && response.data.collectionsdata) {
      const data = response.data.collectionsdata;
      // Group data by category_name
      const groupedData = data.reduce((acc, item) => {
        if (!acc[item.category_name]) {
          acc[item.category_name] = {};
        }
        acc[item.category_name][item.sub_category_id] = {
          ...item,
          checked: false
        };
        return acc;
      }, {});
      setFilterOptions(groupedData);
      // Initialize selectedFilters and tempSelectedFilters with the same structure
      const initialFilters = Object.keys(groupedData).reduce((acc, category) => {
        acc[category] = Object.keys(groupedData[category]).reduce((subAcc, subCatId) => {
          subAcc[subCatId] = false;
          return subAcc;
        }, {});
        return acc;
      }, {});
      setSelectedFilters(initialFilters);
      setTempSelectedFilters(initialFilters);
    }
  } catch (error) {
    console.error("Error fetching filter options:", error);
  }
};


		
 const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
const applyFilters = async () => {
  try {
    setIsFilterLoading(true); // Use separate loading state for filters
    setSelectedFilters(tempSelectedFilters);
    setCurrentPage(1);

    const hasActiveFilters = Object.values(tempSelectedFilters).some(category => 
      Object.values(category).some(val => val)
    );

    setFiltersApplied(hasActiveFilters);

    let filterCategory = "";
    if (hasActiveFilters) {
      filterCategory = Object.entries(tempSelectedFilters)
        .flatMap(([category, subCategories]) => 
          Object.entries(subCategories)
            .filter(([subCatId, isSelected]) => isSelected)
            .map(([subCatId]) => subCatId)
        )
        .join(",");
    }

    const baseUrl = "https://developer.2il.org/Appdev/jsonapi/collectionsapi.php";
    const queryParams = new URLSearchParams();
    
    if (hasActiveFilters) {
      queryParams.append("filterCategory", filterCategory);
    }
    
    if (searchTerm) {
      queryParams.append("searchTerm", searchTerm);
    }
    
    queryParams.append("page", "1");
    
    const apiUrl = `${baseUrl}?${queryParams.toString()}`;
    console.log('Applying filters with URL:', apiUrl);

    const response = await axios.get(apiUrl);
    console.log('Filter Response:', response.data);

    if (response.data && response.data.collectionsdata) {
      const processedData = response.data.collectionsdata.map(collection => ({
        ...collection,
        data_id: collection.data_id || collection.id || Math.random().toString(),
        title: collection.title || 'Untitled',
        description: collection.description || '',
        image: collection.image || 'https://via.placeholder.com/150',
        featured_image: collection.featured_image || 'https://via.placeholder.com/300x140',
        name: collection.name || 'Unknown User',
        categories: Array.isArray(collection.categories) ? collection.categories : []
      }));

      // Update states in sequence
      setAllCollections(processedData);
      setFilteredCollections(processedData);
      setHasMore(processedData.length >= 10);
      
      // Close filter dialog after successful update
      setFilterOpen(false);
    } else {
      setAllCollections([]);
      setFilteredCollections([]);
      setHasMore(false);
    }

  } catch (error) {
    console.error('Error applying filters:', error);
    setError('Failed to apply filters. Please try again.');
  } finally {
    setIsFilterLoading(false);
    setIsLoading(false);
  }
};




  const getSelectedFiltersCount = () => {
    return Object.values(selectedFilters).reduce((count, categoryFilters) => 
      count + Object.values(categoryFilters).filter(Boolean).length, 0
    );
  };
  
   const filterCount = getSelectedFiltersCount(selectedFilters);


  
// Updated resetFilters function with proper state management
// Updated resetFilters function with proper state management and data fetching
const resetFilters = async () => {
  try {
    setIsFilterLoading(true);
    
    // Reset all filter-related states
    const initialFilters = Object.keys(filterOptions).reduce((acc, category) => {
      acc[category] = Object.keys(filterOptions[category]).reduce((subAcc, subCatId) => {
        subAcc[subCatId] = false;
        return subAcc;
      }, {});
      return acc;
    }, {});

    // Reset all states in the correct order
    setTempSelectedFilters(initialFilters);
    setSelectedFilters(initialFilters);
    setFiltersApplied(false);
    setIsFiltering(false);
    setCurrentPage(1);
    setHasMore(true);
    setSearchTerm(''); // Also reset search term
    setIsSearchActive(false);
    setIsSearching(false);

    // Fetch fresh data from the API
    const response = await axios.get("https://developer.2il.org/Appdev/jsonapi/collectionsapi.php?page=1");
    
    if (response.data && response.data.collectionsdata) {
      // Process the fetched data
      const processedData = response.data.collectionsdata.map(collection => ({
        ...collection,
        data_id: collection.data_id || collection.id || Math.random().toString(),
        title: collection.title || 'Untitled',
        description: collection.description || '',
        image: collection.image || 'https://via.placeholder.com/150',
        featured_image: collection.featured_image || 'https://via.placeholder.com/300x140',
        name: collection.name || 'Unknown User',
        categories: collection.categories || []
      }));

      // Update states with new data
      setAllCollections(processedData);
      setFilteredCollections(processedData);
      
      // Show success message
      setSnackbarMessage('Filters have been reset successfully');
      setSnackbarOpen(true);
    } else {
      throw new Error('Invalid data structure received from API');
    }

  } catch (error) {
    console.error('Error resetting filters:', error);
    setError('Failed to reset filters. Please try again.');
    setSnackbarMessage('Failed to reset filters. Please try again.');
    setSnackbarOpen(true);
  } finally {
    setIsFilterLoading(false);
    setFilterOpen(false); // Close the filter dialog
  }
};
  
    
 const handleExpandClick = (event, itemId) => {
    event.stopPropagation();
    setExpandedItemId(itemId);
  };
  
  const handleCloseDialog = () => {
    setExpandedItemId(null);
  };
  
const CollectionCard = ({
  item,
  expandedIds,
  handleExpandClick,
  handleLikeClick,
  handleShareClick,
  handlePinClick,
  likedItems,
  pinnedItems,
  setSnackbarMessage, // Add this line to receive setSnackbarMessage
  setSnackbarOpen     // Add this line to receive setSnackbarOpen
}) => {
	
	
  const isExpanded = expandedIds[item.data_id];
  const truncatedTitle = item.title.length > 100 ? item.title.slice(0, 100) + '...' : item.title;


  return (
       <>
        <Card
  sx={{
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 2,
    boxShadow: 3,
    transition: 'box-shadow 0.3s ease, transform 0.3s ease',
    '&:hover': {
      boxShadow: 6,
      transform: 'scale(1.02)', // Gives a lifted effect on hover
    },
    cursor: 'pointer',
  }}
  onClick={(event) => handleCardClick(item, event)}
>
  {/* User Information Section */}
  <Box display="flex" alignItems="center" padding="10px">
    <CardMedia
      component="img"
      image={item.image} // User image source
      alt={item.name}
      sx={{
        width: 40,
        height: 40,
        borderRadius: '50%',
        marginRight: '20px',
        border: '2px solid',
        borderColor: 'primary.main',
      }}
    />
    <Typography variant="subtitle1" component="div">
      {item.name}
    </Typography>
  </Box>

  {/* Featured Image */}
 <CardMedia
  component="img"
  image={item.featured_image || "https://via.placeholder.com/300x140"}
  alt={item.title}
  sx={{
    height: { xs: 120, sm: 140 }, // Reduced height by ~10-20%
    width: { xs: '90%', sm: '100%' }, // Ensure it takes a smaller portion of the available width
    transition: 'transform 0.3s ease',
    margin: 'auto', // Center align the banner for a more balanced look
    '&:hover': {
      transform: 'scale(1.05)', // Image zoom effect
    },
  }}
/>

  {/* Card Content */}
  <CardContent
    sx={{
      padding: { xs: 1.5, sm: 2 },
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: 'background.default',
    }}
  >
    <Box sx={{ mb: 2 }}>
      <Typography
        variant="h6"
        component="div"
        sx={{
          fontSize: { xs: '1rem', sm: '1.1rem' },
          fontWeight: 600,
          lineHeight: 1.3,
          color: 'text.primary',
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          textOverflow: 'ellipsis',
        }}
      >
        {truncatedTitle}
      </Typography>
      <IconButton
        size="small"
        onClick={(event) => handleExpandClick(event, item.data_id)}
        aria-label="show more"
        sx={{
          alignSelf: 'flex-start',
          transition: 'transform 0.3s',
          '&:hover': {
            transform: 'rotate(180deg)',
          },
        }}
      >
        <ExpandMoreIcon />
      </IconButton>
    </Box>

<Box display="flex" alignItems="center" justifyContent="space-between" mt="auto">
  {/* Like Button */}
  <Box display="flex" alignItems="center">
    <Tooltip title="Like">
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          handleLikeClick(item.data_id);
        }}
        sx={{
          backgroundColor: likedItems[item.data_id] ? 'rgba(25, 118, 210, 0.1)' : 'rgba(0, 0, 0, 0.05)',
          '&:hover': {
            backgroundColor: likedItems[item.data_id] ? 'rgba(25, 118, 210, 0.2)' : 'rgba(0, 0, 0, 0.1)',
            transform: 'scale(1.1)',
          },
          transition: 'all 0.3s ease-in-out',
        }}
      >
        {likedItems[item.data_id] ? (
          <ThumbUpAltIcon sx={{ color: 'primary.main' }} />
        ) : (
          <ThumbUpOffAltIcon sx={{ color: 'action.main' }} />
        )}
      </IconButton>
    </Tooltip>
 
  </Box>

  {/* Share Button */}
  <Tooltip title="Share">
    <IconButton
      onClick={(event) => {
        event.stopPropagation();
        handleShareClick(item.data_id);
      }}
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          transform: 'scale(1.1)',
        },
        transition: 'all 0.3s ease-in-out',
      }}
    >
      <ShareIcon sx={{ color: 'action.main' }} />
    </IconButton>
  </Tooltip>

  {/* Pin Button */}
  <Tooltip title={pinnedItems[item.data_id] ? "Unpin" : "Pin"}>
    <IconButton
      onClick={(event) => {
        event.stopPropagation();
        handlePinClick(item.data_id);
      }}
      sx={{
        backgroundColor: pinnedItems[item.data_id] ? 'rgba(255, 143, 0, 0.1)' : 'rgba(0, 0, 0, 0.05)',
        '&:hover': {
          backgroundColor: pinnedItems[item.data_id] ? 'rgba(255, 143, 0, 0.2)' : 'rgba(0, 0, 0, 0.1)',
          transform: 'scale(1.1)',
        },
        transition: 'all 0.3s ease-in-out',
      }}
    >
      {pinnedItems[item.data_id] ? (
        <PushPinIcon sx={{ color: 'secondary.main' }} />
      ) : (
        <PushPinOutlinedIcon sx={{ color: 'action.main' }} />
      )}
    </IconButton>
  </Tooltip>
</Box>
      </CardContent>
    </Card>

 
    </>
  );
};

const MemoizedCollectionCard = React.memo(CollectionCard); 

const memoizedFilteredCollections = useMemo(() => {
  console.log('Recalculating memoized collections');
  console.log('All Collections:', allCollections);
  console.log('Search Term:', searchTerm);
  console.log('Filters Applied:', filtersApplied);

  // Start with all collections
  let result = allCollections;

  // Apply search filter if there's a search term
  if (searchTerm) {
    result = result.filter((collection) => {
      const title = (collection.title || '').toLowerCase();
      const description = (collection.description || '').toLowerCase();
      const searchLower = searchTerm.toLowerCase();
      return title.includes(searchLower) || description.includes(searchLower);
    });
  }

  // Apply selected filters if filters are applied
  if (filtersApplied) {
    result = result.filter((collection) => {
      const collectionCategories = collection.categories || [];
      
      return Object.entries(selectedFilters).every(([category, subCategories]) => {
        const hasSelectedSubCategories = Object.values(subCategories).some(value => value);
        if (!hasSelectedSubCategories) {
          return true;
        }

        return Object.entries(subCategories).some(([subCategoryId, isSelected]) => {
          if (!isSelected) {
            return false;
          }
          return Array.isArray(collectionCategories) && 
                 collectionCategories.includes(subCategoryId);
        });
      });
    });
  }

  console.log('Filtered Result:', result);
  return result;
}, [allCollections, searchTerm, selectedFilters, filtersApplied]);

return (
 <Container maxWidth="lg">
   {/* Particle Animation Background */}


  <Box
        sx={{
          textAlign: 'center',
          padding: '16px 0 8px 0',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            marginBottom: '12px',
            textShadow: '0 0 15px rgba(100, 181, 246, 0.5)',
          }}
        >
          Collections
        </Typography>


      <GlowingButton onClick={() => handleMyCollectionsClick()}>
        MY COLLECTIONS
      </GlowingButton>


      </Box>
	   


    {/* Top Right Button for "My Collections" */}
		   <Box sx={{   
		  marginTop: '16px', 
          display: 'flex',
          justifyContent: 'center', }}>
     
    </Box>
     {/* Sticky Search Bar */}
       {/* Sticky Search Bar */}

<AppBar
  position="sticky"
  ref={searchBarRef}
  className={clsx({ 'sticky-header': isSticky })}
  sx={{
    top: 0,
    left: 0,
    right: 0,
    ...transitionStyles,
    backgroundColor: isSticky 
      ? '#FFFFFF' 
      : 'rgba(255, 255, 255, 0.9)',
    boxShadow: isSticky 
      ? '0px 2px 6px rgba(0, 0, 0, 0.15)' 
      : 'none',
    zIndex: 1200,
    width: isSticky ? '110%' : '100%',
    marginLeft: isSticky ? '-5%' : '0',
    padding: isSticky ? '6px 12px' : '12px 24px',
    borderRadius: isSticky ? '0' : '20px',
    backdropFilter: 'blur(10px)',
    transformOrigin: 'top',
  }}
>
  <Toolbar 
    disableGutters
    sx={{
      ...transitionStyles,
      padding: isSticky ? '0 16px' : '0 24px',
    }}
  >
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        background: isSticky
          ? '#ffffff'
          : 'linear-gradient(135deg, rgba(63, 81, 181, 0.1), rgba(100, 181, 246, 0.3))',
        padding: isSticky ? '8px 16px' : '12px 24px',
        borderRadius: '16px',
        boxShadow: isSticky 
          ? 'none' 
          : '0px 4px 20px rgba(0, 0, 0, 0.1)',
        ...transitionStyles,
      }}
    >
      {/* Search Field */}
      <TextField
        fullWidth
        variant="outlined"
        label="Search collections..."
        value={searchTerm}
        onChange={handleSearch}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleExternalSearch();
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isSearchActive && (
                <IconButton 
                  onClick={handleClearSearch} 
                  edge="end"
                  sx={{
                    ...transitionStyles,
                    opacity: isSearchActive ? 1 : 0,
                  }}
                >
                  <ClearIcon sx={{ fontSize: '1.25rem' }} />
                </IconButton>
              )}
            </InputAdornment>
          ),
          sx: {
            borderRadius: '16px',
            padding: '4px 10px',
            fontSize: '0.875rem',
            height: isSticky ? '40px' : '48px',
            ...transitionStyles,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
              ...transitionStyles,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.1)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'primary.main',
            },
          },
        }}
        sx={{
          ...transitionStyles,
          '& .MuiInputLabel-root': {
            ...transitionStyles,
            transform: isSticky 
              ? 'translate(14px, 10px) scale(0.75)'
              : 'translate(14px, 14px) scale(0.75)',
          },
        }}
      />

      {/* Filter Button */}
 {filterCount > 0 ? (
    <Badge
      badgeContent={filterCount}
      color="primary"
      sx={{
        ...transitionStyles,
        '& .MuiBadge-badge': {
          top: '30%',
          right: '-10px',
          transform: 'none',
          pointerEvents: 'none',
          ...transitionStyles,
        },
      }}
    >
      <IconButton
        onClick={() => setFilterOpen(true)}
        sx={{
          ...transitionStyles,
          padding: isSticky ? '8px' : '10px',
          backgroundColor: 'rgba(63, 81, 181, 0.1)',
          '&:hover': {
            backgroundColor: 'rgba(63, 81, 181, 0.3)',
            transform: 'scale(1.1)',
          },
        }}
      >
        <FilterListIcon />
      </IconButton>
    </Badge>
  ) : (
    <IconButton
      onClick={() => setFilterOpen(true)}
      sx={{
        ...transitionStyles,
        padding: isSticky ? '8px' : '10px',
        backgroundColor: 'rgba(63, 81, 181, 0.1)',
        '&:hover': {
          backgroundColor: 'rgba(63, 81, 181, 0.3)',
          transform: 'scale(1.1)',
        },
      }}
    >
      <FilterListIcon />
    </IconButton>
  )}
    </Box>
  </Toolbar>
</AppBar>
      {/* Header Carousel Placeholder */}
      <Box sx={{ width: '110%', marginLeft: '-18px', marginTop: isSticky ? '8px' : '0px' }}>
        {!isSearchActive &&

		<ErrorBoundary>
  <Suspense fallback={<div>Loading...</div>}>
    <LazyHeaderCarousel />
  </Suspense>
</ErrorBoundary>
		
		}
      </Box>
    
			
    {/* Display error message if error occurs */}
    {error && (
      <Typography color="error" align="center">
        {error}
      </Typography>
    )}

  
      {filteredCollections.length === 0 && (isFiltering || isSearching) && !isLoading ? (
  <Box textAlign="center" mt={4}>
    <Typography variant="h6" color="textSecondary">
      No collections found. Try searching on these platforms:
    </Typography>
    <Box display="flex" justifyContent="center" mt={2} mb={2}>
		  {searchEngines.map((engine) => (
			<SearchEngineIcon
			  key={engine.searchenginename}
			  isSelected={selectedEngines.some(e => e.searchenginename === engine.searchenginename)}
			  onClick={() => handleEngineToggle(engine)}
			>
			  <img
				src={`https://developer.2il.org/Appdev/${engine.searchengineimage.replace('../', '')}`}
				alt={engine.searchenginename}
			  />
			</SearchEngineIcon>
		  ))}

    </Box>
    <Button
      variant="contained"
      color="primary"
      onClick={handleExternalSearch}
      disabled={selectedEngines.length === 0}
    >
      SEARCH
    </Button>
  </Box>
) : (
<InfiniteScroll
  dataLength={filteredCollections.length}
  next={!isSearching ? loadMore : null}
  hasMore={!isSearching && hasMore}
  loader={<InfiniteLoader isLoading={isFetchingMore} />}
  endMessage={
    !isSearching && !hasMore && (
      <Typography textAlign="center" mt={1} color="textSecondary">
        {filteredCollections.length === 0 ? 'No collections found.' : 'No more collections to load.'}
      </Typography>
    )
  }
>

  {isFilterLoading ? (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
      <LottieLoader />
    </Box>
  ) : (
    <>
      <Grid container spacing={{ xs: 2, sm: 3 }} sx={{ mt: { xs: 1, sm: 2 } }}>
        {filteredCollections.map((item, index) => (
          <Grid item key={item.data_id} xs={6} sm={6} md={4} lg={3} sx={{ mb: { xs: 2, sm: 3 } }}>
            <CollectionCard 
              item={item}
              expandedIds={expandedIds}
              handleExpandClick={handleExpandClick}
              handleLikeClick={handleLikeClick}
              handleShareClick={handleShareClick}
              handlePinClick={handlePinClick}
              likedItems={likedItems}
              pinnedItems={pinnedItems}
              likeCounts={likeCounts}
            />
          </Grid>
        ))}
      </Grid>

      {/* Loader after grid */}

    </>
  )}
</InfiniteScroll>
)}


    {/* Dialog for Pinned Collections */}
 {/* Dialog for Pinned Collections */}
{/* Dialog for Pinned Collections */}



<Dialog open={filterOpen} onClose={() => setFilterOpen(false)} fullWidth maxWidth="sm">
  <DialogTitle
    sx={{
      background: 'linear-gradient(to right, #ff4081, #ff80ab)',
      color: '#FFFFFF',
      textAlign: 'center',
      fontWeight: 'bold',
      padding: '24px',
      borderRadius: '20px 20px 0 0',
    }}
  >
    Filter Collections
  </DialogTitle>
  <DialogContent
    sx={{
      background: 'linear-gradient(to bottom, #ffffff, #f0f4f8)',
      padding: '24px',
      overflowY: 'auto',
    }}
  >
    {Object.entries(filterOptions).map(([category, subCategories]) => (
      <Box key={category} mb={3}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            marginBottom: '12px',
            color: '#ff4081',
            textDecoration: 'underline',
          }}
        >
          {category}
        </Typography>
        <FormGroup>
          {Object.entries(subCategories).map(([subCategoryId, subCategory]) => (
            <FormControlLabel
              key={subCategoryId}
              control={
                <Checkbox
                  checked={tempSelectedFilters[category]?.[subCategoryId] || false}
                  onChange={() => handleFilterChange(category, subCategoryId)}
                  sx={{
                    '&.Mui-checked': {
                      color: '#ff4081',
                    },
                  }}
                />
              }
              label={
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(255, 64, 129, 0.1)',
                      borderRadius: '8px',
                      padding: '4px',
                    },
                    transition: 'all 0.3s ease',
                  }}
                >
                  <img
                    src={subCategory.dataurl}
                    alt={subCategory.sub_category_name}
                    style={{ width: 24, height: 24, marginRight: 8 }}
                  />
                  {subCategory.sub_category_name}
                </Box>
              }
            />
          ))}
        </FormGroup>
      </Box>
    ))}
  </DialogContent>
  <DialogActions
  sx={{
    background: '#f9f9f9',
    padding: '16px',
    justifyContent: 'space-between',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '0 0 20px 20px',
  }}
>
  <Button
    onClick={resetFilters}
    variant="outlined"
    color="secondary"
    disabled={isFilterLoading}
    sx={{
      fontWeight: 'bold',
      padding: '10px 24px',
      borderRadius: '25px',
      '&:hover': {
        backgroundColor: '#ff4081',
        transform: 'scale(1.05)',
        color: '#ffffff',
      },
      transition: 'all 0.3s ease',
    }}
  >
    Reset
  </Button>
  <Button
    onClick={() => setFilterOpen(false)}
    color="error"
    disabled={isFilterLoading}
    sx={{
      fontWeight: 'bold',
      padding: '10px 24px',
      borderRadius: '25px',
      '&:hover': {
        backgroundColor: 'rgba(255, 64, 129, 0.1)',
        transform: 'scale(1.05)',
      },
      transition: 'all 0.3s ease',
    }}
  >
    Cancel
  </Button>
  <Button
    onClick={applyFilters}
    variant="contained"
    color="primary"
    disabled={isFilterLoading}
    sx={{
      fontWeight: 'bold',
      padding: '12px 32px',
      borderRadius: '25px',
      background: 'linear-gradient(to right, #ff4081, #ff80ab)',
      boxShadow: '0px 4px 15px rgba(255, 64, 129, 0.5)',
      '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0px 6px 25px rgba(255, 64, 129, 0.7)',
      },
      transition: 'all 0.3s ease',
    }}
  >
    {isFilterLoading ? 'Applying...' : 'Apply'}
  </Button>
</DialogActions>
</Dialog>




    {/* Snackbar for Pinning */}
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={3000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <Alert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%' }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>

   <Dialog open={pinnedCollectionsOpen} onClose={() => setPinnedCollectionsOpen(false)} fullWidth maxWidth="md">
      <DialogTitle
        sx={{
          background: 'linear-gradient(to right, #6a11cb, #2575fc)',
          padding: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: 'white',
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        }}
      >
        <Box display="flex" alignItems="center">
          <Avatar sx={{ bgcolor: '#ff4081', marginRight: 2 }}>{pinnedCollections.length > 0 ? 'P' : 'P'}</Avatar>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            Hello, {pinnedCollections.length > 0 ? 'User' : 'User'}! Here are your pinned collections
          </Typography>
        </Box>
        <IconButton onClick={() => setPinnedCollectionsOpen(false)}>
          <ClearIcon sx={{ color: 'white' }} />
        </IconButton>
      </DialogTitle>

      <DialogContent
        dividers
        sx={{
          padding: 4,
          background: 'linear-gradient(to bottom, #ffffff, #f3f7fa)',
        }}
      >
        {pinnedCollections.length === 0 ? (
          <Typography variant="body1" color="textSecondary" textAlign="center">
            No pinned collections available.
          </Typography>
        ) : (
          <Grid container spacing={3}>
            {pinnedCollections.map((item) => (
              <Grid item key={item.data_id} xs={12} sm={6} md={6} lg={4}>
                <StyledCard>
                  <Box display="flex" alignItems="center" padding="10px">
                    <Avatar
                      src={item.image}
                      alt={item.name}
                      sx={{
                        width: 50,
                        height: 50,
                        border: '3px solid',
                        borderColor: 'secondary.main',
                        marginRight: 2,
                      }}
                    />
                    <Typography variant="subtitle1" component="div">
                      {item.name}
                    </Typography>
                  </Box>

                  <CardMedia
                    component="img"
                    image={item.featured_image || "https://via.placeholder.com/300x140"}
                    alt={item.title}
                    sx={{
                      height: { xs: 140, sm: 160 },
                      objectFit: 'cover',
                      width: '100%',
                      filter: 'brightness(0.9)',
                      transition: 'filter 0.3s ease',
                      '&:hover': {
                        filter: 'brightness(1)',
                      },
                    }}
                  />
                  <CardContent
                    sx={{
                      padding: 3,
                      textAlign: 'center',
                      background: 'linear-gradient(to top, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5))',
                    }}
                  >
                    <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
                      {item.title.length > 50 ? `${item.title.slice(0, 50)}...` : item.title}
                    </Typography>
                    <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                      {/* Like Button */}
                      <Tooltip title="Like">
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            handleLikeClick(item.data_id);
                          }}
                          sx={{
                            backgroundColor: likedItems[item.data_id] ? 'rgba(25, 118, 210, 0.15)' : 'rgba(0, 0, 0, 0.05)',
                            '&:hover': {
                              backgroundColor: likedItems[item.data_id] ? 'rgba(25, 118, 210, 0.25)' : 'rgba(0, 0, 0, 0.1)',
                              transform: 'scale(1.2)',
                              boxShadow: '0 4px 15px rgba(25, 118, 210, 0.3)',
                            },
                            transition: 'all 0.3s ease-in-out',
                          }}
                        >
                          {likedItems[item.data_id] ? (
                            <ThumbUpAltIcon sx={{ color: 'primary.main' }} />
                          ) : (
                            <ThumbUpOffAltIcon sx={{ color: 'action.main' }} />
                          )}
                        </IconButton>
                      </Tooltip>

                      {/* Share Button */}
                      <Tooltip title="Share">
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            handleShareClick(item.data_id);
                          }}
                          sx={{
                            backgroundColor: 'rgba(0, 0, 0, 0.05)',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.1)',
                              transform: 'scale(1.2)',
                              boxShadow: '0 0 10px rgba(100, 181, 246, 0.5)',
                            },
                            transition: 'all 0.3s ease-in-out',
                          }}
                        >
                          <ShareIcon sx={{ color: 'action.main' }} />
                        </IconButton>
                      </Tooltip>

                      {/* Pin/Unpin Button */}
                      <Tooltip title={pinnedItems[item.data_id] ? "Unpin" : "Pin"}>
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            handlePinClick(item.data_id);
                          }}
                          sx={{
                            backgroundColor: pinnedItems[item.data_id] ? 'rgba(255, 143, 0, 0.15)' : 'rgba(0, 0, 0, 0.05)',
                            '&:hover': {
                              backgroundColor: pinnedItems[item.data_id] ? 'rgba(255, 143, 0, 0.3)' : 'rgba(0, 0, 0, 0.1)',
                              transform: 'rotate(10deg) scale(1.15)',
                              boxShadow: '0 0 15px rgba(255, 87, 34, 0.5)',
                            },
                            transition: 'all 0.3s ease-in-out',
                          }}
                        >
                          {pinnedItems[item.data_id] ? (
                            <PushPinIcon sx={{ color: 'secondary.main' }} />
                          ) : (
                            <PushPinOutlinedIcon sx={{ color: 'action.main' }} />
                          )}
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </CardContent>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          padding: '16px 24px',
          backgroundColor: '#f5f5f5',
          justifyContent: 'center',
        }}
      >
        <Button
          onClick={() => setPinnedCollectionsOpen(false)}
          color="primary"
          sx={{
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: 'primary.light',
              transform: 'scale(1.05)',
            },
            transition: 'all 0.3s ease-in-out',
          }}
        >
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  <StyledDialog open={expandedItemId !== null} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
  {expandedItemId && (
    // Define expandedItem based on expandedItemId
    (() => {
      const expandedItem = filteredCollections.find(item => item.data_id === expandedItemId);
      return (
        <>
          {/* Dialog Header with Gradient Background */}
          <DialogTitle
            sx={{
              background: 'linear-gradient(to right, #6a11cb, #2575fc)',
              color: '#FFFFFF',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '24px',
              position: 'relative',
            }}
          >
            <Box display="flex" alignItems="center">
             <CollectionsBookmarkIcon sx={{ marginRight: 1 }} />
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                {expandedItem?.title}
              </Typography>
            </Box>
           
          </DialogTitle>

          {/* Main Content Area */}
          <DialogContent
            sx={{
              padding: '24px',
              background: 'rgba(255, 255, 255, 0.9)',
            }}
          >
            <Typography variant="body1" paragraph>
              {expandedItem?.description || 'No description available.'}
            </Typography>
            {/* Add any additional content here as needed */}
          </DialogContent>

          {/* Footer Section with Enhanced Button */}
          <DialogActions sx={{ padding: '16px', justifyContent: 'center' }}>
            <StyledButton onClick={handleCloseDialog}>Close</StyledButton>
          </DialogActions>
        </>
      );
    })()
  )}
</StyledDialog>
  </Container>
);


};

export default CollectionsPage;
