import React from 'react';
import { Box, Typography } from '@mui/material';

const FooterComponent = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: 'transparent',
        color: '#fff',
        padding: '10px',
        textAlign: 'center',
        marginTop: '10px',
      }}
    >
      {/* Spacer - this adds space above the footer */}
      <Box sx={{ height: '20px' }} /> {/* Adjust the height of the spacer as needed */}

     
    </Box>
  );
};

export default FooterComponent;
